import { useParams } from 'react-router-dom';
import { TerrainViewer } from './TerrainViewer';
import Cookies from 'js-cookie';

export const TerrainEmbedded = () => {
    const  { "*": terrain } = useParams();

    if (!terrain) {
        return null;
    }

    const project = Cookies.get('project') || "default";

    return (
        <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
            <TerrainViewer project={project} terrain={terrain} />
        </div>
    );
}