import { acceptCompletion, autocompletion, CompletionContext } from '@codemirror/autocomplete';
import { syntaxTree } from "@codemirror/language"
import { ComponentsList, ModTypes } from './Constants';
import { definitionCompletions } from '../resources/definitions';

export class Completions {
    private value: any;
    private properties: any;
    private keys: any;

    async getContextInfo(context: CompletionContext): Promise<{ type: string, properties: any[] }[]> {
        let syntax = syntaxTree(context.state).resolveInner(context.pos, -1);

        // Get the names of all parent objects
        let parents: { type: string, properties: any[] }[] = [];

        if (syntax.type.name === "String") {
            if (syntax.parent?.type.name === "Property") {
                let text = context.state.sliceDoc(syntax.parent.from, syntax.parent.to);

                if (text == null) {
                    return [];
                }

                let split = text.split(":");
                let name = split[0].trim().replace('"', "").replace('"', "");
                let value = split.length > 1 ? split[1].split(",")[0].trim() : null;

                if (value?.startsWith("{") || value?.startsWith("[")) {
                    value = null;
                }

                parents.push({ type: "-", properties: [{ name: name, value: value }] });
            }
        }

        while (true) {
            if (syntax.type.name === "Object") {
                // Get all the properties of the object
                let properties = syntax.getChildren("Property");

                if (properties == null) {
                    break;
                }

                let propertyNames = properties.map(property => {
                    let text = context.state.sliceDoc(property.from, property.to);

                    if (text == null) {
                        return "";
                    }

                    let split = text.split(":");
                    let name = split[0].trim().replace('"', "").replace('"', "");
                    let value = split.length > 1 ? split[1].split(",")[0].trim() : null;

                    if (value?.startsWith("{") || value?.startsWith("[")) {
                        value = null;
                    }

                    return { name: name, value: value };
                });

                if (syntax.parent == null) {
                    break;
                }

                while (syntax.parent && syntax.parent.type.name === "Array") {
                    parents.push({ type: "{}", properties: propertyNames });
                    propertyNames = [];
                    syntax = syntax.parent;
                }

                if (syntax.parent == null) {
                    break;
                }

                let proprtyName = syntax.parent.childBefore(syntax.from);

                if (proprtyName == null) {
                    break;
                }

                let text = context.state.sliceDoc(proprtyName.from + 1, proprtyName.to - 1);

                if (text == null) {
                    break;
                }

                parents.push({ type: text, properties: propertyNames });
            }

            if (syntax.parent == null) {
                break;
            }

            syntax = syntax.parent;
        }

        let keys = Object.keys(this.value);
        let kv = keys.map(key => {
            return { name: key, value: this.value[key] };
        });

        parents.push({ type: "{}", properties: kv });

        return parents;
    }

    async myCompletions(context: CompletionContext) {

        let word = context.matchBefore(/\w*/);
        if (word == null || word.from == word.to && !context.explicit) {
            return null;
        }

        let completions: any[] = [];

        /*
        const mods = await modServic.getMods("test");

        // For reach mod, add a completion
        for (let i = 0; i < mods.length; i++) {
            completions.push({
                label: mods[i], type: "variable"
            });
        }

        let syntax = syntaxTree(context.state).resolveInner(context.pos, -1);

        console.log(syntax);

        let ctx = await this.getContextInfo(context);

        if (ctx.length > 0) {
            let parent = ctx[0];

            if (parent.type === "-") {
                // Check the 'properties' for suggestions
                let suggestions = this.properties[parent.properties[0].name];

                if (suggestions) {
                    // Check that we have the correct parents
                    let valid = true;
                    if (suggestions.parents) {

                        for (let i = 0; i < suggestions.parents.length; i++) {
                            console.log("Checking " + ctx[i+1].type + " against " + suggestions.parents[i]);
                            if (suggestions.parents[i] !== ctx[i+1].type) {
                                valid = false;
                                break;
                            }
                        }
                    }

                    if (valid) {
                        for (let i = 0; i < suggestions.values.length; i++) {
                            completions.push({ label: suggestions.values[i], type: "keyword" });
                        }
                    }
                }
            } else {
                for (let i = 0; i < this.keys.length; i++) {
                    let valid = true;
                    if (this.keys[i].parents) {
                        for (let j = 0; j < this.keys[i].parents.length; j++) {
                            if (j >= ctx.length) {
                                valid = false;
                                break;
                            }

                            console.log("Checking " + ctx[j].type + " against " + this.keys[i].parents[j]);

                            if (this.keys[i].parents[j] === "*") continue;

                            if (this.keys[i].parents[j] !== ctx[j].type) {
                                valid = false;
                                break;
                            }
                        }
                    }

                    if (valid) {
                        // Push the values
                        for (let j = 0; j < this.keys[i].values.length; j++) {
                            // Check that the value is not already in the parent
                            let found = false;
                            for (let k = 0; k < parent.properties.length; k++) {
                                if (parent.properties[k].name === this.keys[i].values[j]) {
                                    found = true;
                                    break;
                                }
                            }
                            
                            if (!found) {
                                completions.push({ label: this.keys[i].values[j], type: "keyword" });
                            }
                        }
                    }
                }
            }
        }

        // Log each parent
        for (let i = 0; i < ctx.length; i++) {
            console.log(ctx[i]);
        }
        */

        definitionCompletions.forEach(definition => {
            completions.push(definition);
        });

        return {
            from: word.from,
            options: completions
        }
    }

    constructor(value: any) {
        this.value = value;
        this.properties = {
            "component": {
                parents: ["{}"],
                values: ComponentsList
            },
            "action": {
                parents: ["{}"],
                values: [
                    "add",
                    "remove"
                ]
            },
            "type": {
                parents: ["{}"],
                values: ModTypes
            },
            "component-type": {
                parents: ["{}", "components"],
                values: ComponentsList
            }
        };
        this.keys = [
            { parents: ["{}"], values: ["name", "type", "action", "values", "locales", "components", "component"] },
            { parents: ["*", "locales"], values: ["en_US", "de_DE", "en_GB"] },
            { parents: ["{}", "components"], values: ["component-type", "component-id"] }
        ]
    }

}
