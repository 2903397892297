import { useEffect, useRef } from 'react';
import {
    Row, Col
} from 'react-bootstrap';
import './components.css';
// States
import { useState } from 'react';

import Cookies from 'js-cookie';
import { GenericBtn } from './GenericBtn';
import { GenericInput } from './GenericInput';
import { GenericEnum } from './GenericEnum';
import { GenericText } from './GenericText';
import { authorization, Permission } from '../Authorization';
import { adminApi } from './NejlikaApi';

interface InviteUserProps {
    onDone: () => void;
}

const adminLevels = {
    "Civilian": 0,
    "Forum Moderator": 1,
    "Junior Moderator": 2,
    "Moderator": 3,
    "Senior Moderator": 4,
    "Lead Moderator": 5,
    "Junior Developer": 6,
    "Inactive Developer": 7,
    "Developer": 8,
    "Operator": 9
};

export const InviteUser: React.FC<InviteUserProps> = ({ onDone }) => {
    const [discordName, setDiscordName] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [permissionReadResources, setPermissionReadResources] = useState<boolean>(false);
    const [permissionWriteResources, setPermissionWriteResources] = useState<boolean>(false);
    const [permissionReadMods, setPermissionReadMods] = useState<boolean>(false);
    const [permissionWriteMods, setPermissionWriteMods] = useState<boolean>(false);
    const [permissionDeploy, setPermissionDeploy] = useState<boolean>(false);
    const [permissionLogin, setPermissionLogin] = useState<boolean>(false);
    const [adminLevel, setAdminLevel] = useState<keyof typeof adminLevels>('Civilian');
    const [permissionInviteUsers, setPermissionInviteUsers] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [preset, setPreset] = useState<string>('Player');

    const onPlayerPreset = () => {
        setPermissionReadResources(true);
        setPermissionWriteResources(false);
        setPermissionReadMods(false);
        setPermissionWriteMods(false);
        setPermissionDeploy(false);
        setPermissionLogin(true);
        setAdminLevel('Civilian');
        setPermissionInviteUsers(false);
    }

    const onModeratorPreset = () => {
        setPermissionReadResources(true);
        setPermissionWriteResources(false);
        setPermissionReadMods(true);
        setPermissionWriteMods(false);
        setPermissionDeploy(false);
        setPermissionLogin(true);
        setAdminLevel('Moderator');
        setPermissionInviteUsers(false);
    }

    const onTeamPreset = () => {
        setPermissionReadResources(true);
        setPermissionWriteResources(true);
        setPermissionReadMods(true);
        setPermissionWriteMods(true);
        setPermissionDeploy(true);
        setPermissionLogin(true);
        setAdminLevel('Developer');
        setPermissionInviteUsers(false);
    }

    const onAdminPreset = () => {
        setPermissionReadResources(true);
        setPermissionWriteResources(true);
        setPermissionReadMods(true);
        setPermissionWriteMods(true);
        setPermissionDeploy(true);
        setPermissionLogin(true);
        setAdminLevel('Operator');
        setPermissionInviteUsers(true);
    }

    const project = Cookies.get('project') || '';

    const canInvite = authorization.hasPermissionForProject(Permission.InviteUsers, project);

    if (!canInvite) {
        return (
            <Row>
                <Col md={12} className='center-text'>
                    <p className='red-text'>You do not have permission to invite users to this project.</p>
                </Col>
            </Row>
        );
    }

    return (
        <div>
            <Row className='mp-0 border-bottom'>
                <Col md={12}>
                    <GenericInput title='Discord Name' type="text" value={discordName} setValue={setDiscordName} />
                </Col>
                <Col md={12}>
                    <GenericEnum title='Select Preset' options={[
                        "Player",
                        "Moderator",
                        "Team",
                        "Admin",
                        "Custom"
                    ]} setValue={(value: string) => {
                        if (value === "Player") {
                            onPlayerPreset();
                        } else if (value === "Moderator") {
                            onModeratorPreset();
                        } else if (value === "Team") {
                            onTeamPreset();
                        } else if (value === "Admin") {
                            onAdminPreset();
                        }
                        setPreset(value);
                    } } value={preset} />
                </Col>
                <Col md={12}>
                    <GenericText title='Permissions' />
                </Col>
                <Col md={12}>
                    <GenericInput title='Read Resources' type="boolean" value={permissionReadResources} setValue={(value) => {
                        setPermissionReadResources(value);
                        setPreset('Custom');
                    }} />
                </Col>
                <Col md={12}>
                    <GenericInput title='Write Resources' type="boolean" value={permissionWriteResources} setValue={(value) => {
                        setPermissionWriteResources(value);
                        setPreset('Custom');
                    }} />
                </Col>
                <Col md={12}>
                    <GenericInput title='Read Mods' type="boolean" value={permissionReadMods} setValue={(value) => {
                        setPermissionReadMods(value);
                        setPreset('Custom');
                    }} />
                </Col>
                <Col md={12}>
                    <GenericInput title='Write Mods' type="boolean" value={permissionWriteMods} setValue={(value) => {
                        setPermissionWriteMods(value);
                        setPreset('Custom');
                    }} />
                </Col>
                <Col md={12}>
                    <GenericInput title='Deploy' type="boolean" value={permissionDeploy} setValue={(value) => {
                        setPermissionDeploy(value);
                        setPreset('Custom');
                    }} />
                </Col>
                <Col md={12}>
                    <GenericInput title='Login' type="boolean" value={permissionLogin} setValue={(value) => {
                        setPermissionLogin(value);
                        setPreset('Custom');
                    }} />
                </Col>
                <Col md={12}>
                    <GenericEnum title='Admin Level' options={[
                        "Civilian",
                        "Forum Moderator",
                        "Junior Moderator",
                        "Moderator",
                        "Senior Moderator",
                        "Lead Moderator",
                        "Junior Developer",
                        "Inactive Developer",
                        "Developer",
                        "Operator"
                    ]} setValue={(value: string) => {
                        setAdminLevel(value as keyof typeof adminLevels);
                        setPreset('Custom');
                    } } value={adminLevel} />
                </Col>
                <Col md={12}>
                    <GenericInput title='Manage Users' type="boolean" value={permissionInviteUsers} setValue={(value) => {
                        setPermissionInviteUsers(value);
                        setPreset('Custom');
                    }} />
                </Col>
                <Col md={12} className='center-text'>
                    <GenericBtn title='Invite User' onClick={() => {
                        const adminInt = adminLevels[adminLevel];

                        adminApi.inviteUser(
                            project,
                            {
                                'discord-name': discordName,
                                'read-resources': permissionReadResources,
                                'write-resources': permissionWriteResources,
                                'read-mods': permissionReadMods,
                                'write-mods': permissionWriteMods,
                                deploy: permissionDeploy,
                                login: permissionLogin,
                                'admin-level': adminInt,
                                'invite-users': permissionInviteUsers
                            },
                        ).then((response) => {
                            if (!response) {
                                setError('Failed to invite user.');
                                setMessage(null);
                                return;
                            }

                            setMessage('User invited successfully.');
                            setError(null);
                        });
                    }} />
                </Col>
            </Row>
            <Row>
                {message &&
                <Col md={12} className='center-text'>
                    <p className='yellow-text'>{message}</p>
                </Col>}
                {error &&
                <Col md={12} className='center-text'>
                    <p className='red-text'>{error}</p>
                </Col>}
                {!error &&
                <Col md={12} className='center-text'>
                    <GenericBtn title='Done' onClick={onDone} />
                </Col>
                }
            </Row>
        </div>
    );
}