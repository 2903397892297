import { useCallback, useState } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { SimplePropsEntry } from '../SimplePropEntry';
import './behavior.css';
import { rightAnchor } from "./BehaviorStyles";
import { GenericBtn } from '../GenericBtn';
import { resourceApi } from '../NejlikaApi';

interface Props {
    project: string;
    value: any;
    setValue: (value: any) => void;
}

export const BehaviorRoot: React.FC<NodeProps<Props>> = (props) => {
    //const { value, setValue } = props.data;

    const [cost, internalSetCost] = useState<number>(props.data.value.values["imagination-cost"] || 0);
    const [cooldown, internalSetCooldown] = useState<number>(props.data.value.values["cooldown"] || 0);
    const [cooldownGroup, internalSetCooldownGroup] = useState<number>(props.data.value.values["cooldown-group"] || 0);
    const [showIconSelect, setShowIconSelect] = useState<boolean>(false);

    const setCost = useCallback((value: any) => {
        console.log(value);
        internalSetCost(value);
        props.data.value.values["imagination-cost"] = value;
        props.data.setValue(props.data.value);
    }, [props.data.value]);

    const setCooldown = useCallback((value: any) => {
        console.log(value);
        internalSetCooldown(value);
        props.data.value.values["cooldown"] = value;
        props.data.setValue(props.data.value);
    }, [props.data.value]);

    const setCooldownGroup = useCallback((value: any) => {
        console.log(value);
        internalSetCooldownGroup(value);
        props.data.value.values["cooldown-group"] = value;
        props.data.setValue(props.data.value);
    }, [props.data.value]);

    const hasIcon = props.data.value["values"]["icon"] && props.data.value["values"]["icon"] !== "lego-universe:0";
    const iconUrl = resourceApi.iconUrl(props.data.project, props.data.value["values"]["icon"]);

    if (!hasIcon) {
        props.data.value["values"]["icon"] = "lego-universe:0";
    }

    const onSelectIcon = () => {
        setShowIconSelect(true);
    }

    return (
        <div className='vs-bg node-border' style={{ width: 500 }}>
            <div>
                <h5 className='wheat-text'>{props.data.value.name}</h5>

                <div className='node-prop'>
                    <SimplePropsEntry value={cost} setValue={setCost} title="Cost" inputType="number" nullable={false} />
                </div>
                <div className='node-prop'>
                    <SimplePropsEntry value={cooldown} setValue={setCooldown} title="Cooldown" inputType="number" nullable={false} />
                </div>
                <div className='node-prop'>
                    <SimplePropsEntry value={cooldownGroup} setValue={setCooldownGroup} title="Cooldown Group" inputType="number" nullable={false} />
                </div>
                <div>
                    {hasIcon &&
                        <img height={100} width={100} src={iconUrl} alt="icon" onClick={onSelectIcon} />
                    }
                    {!hasIcon &&
                        <GenericBtn title='Select Icon' onClick={onSelectIcon} />
                    }
                </div>
            </div>
            <Handle type="source" className='node-anchor' style={rightAnchor} position={Position.Right} id="root">
                <p className='wheat-text output-selector-option'>root</p>
            </Handle>
        </div>
    );
}