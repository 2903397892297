import React from 'react';
import logo from './logo.svg';
import './App.css';
import Cookies from 'js-cookie';

/*
async function loadSkill(id: number, prefix: string): Promise<any> {
    const response = await fetch(`${this.endpoint}/mod/load/skill`, this.addHeaders({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "id": id,
            "prefix": prefix
        })
    }));

    if (!response.ok) {
        console.log(response);
        return {};
    }

    const data = await response.json();

    let mod = data["json"];

    const findBehavior = (name: string) => {
        for (const behavior of mod.values.behaviors) {
            if (behavior.name === name) {
                return behavior;
            }
        }

        return null;
    };

    let depths: { [key: number]: number } = {};
    let positions: {[key: string]: {depth: number, siblingNr: number}} = {};

    const computePositions = (node: any, depth: number) => {
        // If this behavior has been visited, return
        if (positions[node.name]) {
            return;
        }

        if (depths[depth] === undefined) {
            depths[depth] = 0;
        }

        if (positions[node.name] === undefined) {
            positions[node.name] = {depth: depth, siblingNr: depths[depth]};
        }

        depths[depth]++;

        if (!node.values.parameters) {
            return;
        }

        console.log(node.values.parameters)

        for (const key of Object.keys(node.values.parameters)) {
            const child = node.values.parameters[key];
            if (typeof child === "string") {
                const behavior = findBehavior(child);

                if (behavior) {
                    computePositions(behavior, depth + 1);
                }
            }
        }
    };

    computePositions(findBehavior(mod.values.behavior), 0);

    // Each column is 200 in width (x), each row is 100 in height (y)
    for (const behavior of mod.values.behaviors) {
        const position = positions[behavior.name];

        // Normalize the siblingNr, based on the number of siblings
        let numberOfSiblings = depths[position.depth];
        let siblingNr = position.siblingNr;
        let normalizedSiblingNr = siblingNr - (numberOfSiblings - 1) / 2;


        if (position) {
            behavior["extra-data"] = {
                position: {
                    x: position.depth * 500 + 800,
                    y: normalizedSiblingNr * 100
                }
            }
        }
    }

    return mod;
}
    */

function LoadHome() {
    const [id, setId] = React.useState<number>(0);

    const onLoad = async () => {
        const pack = Cookies.get('package');

        /*modServic.loadSkill(id, pack + ":skill:" + id).then((data) => {
            console.log(data);

            modServic.createMod(data).then((result) => {
                //window.location.href = `/?uid=${result.uid}`;
            });
        });*/
    };

    return (
        <div className='row' id='main'>
            <div className='col-12 vs-bg'>
                <div className='center-text'>
                    <h1 className='wheat-text'>Load</h1>
                    <input type='number' className='wheat-text' value={id} onChange={(e) => setId(parseInt(e.target.value))} />
                    <button className='btn btn-primary' onClick={onLoad}>Load</button>
                </div>          
            </div>
        </div>
    );
}

export default LoadHome;
