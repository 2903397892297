import React, { Suspense, useCallback, useEffect, useState } from "react";
import "./EquippedGear.css";
import { ModelViewer } from "../../Graphics/ModelViewer";
import { Vector3 } from "@babylonjs/core";
import { LogMessage, Severity } from "../../Logger";
import { resourceApi, sqlApi } from "../../NejlikaApi";

interface EquippedGearProps {
    project: string,
    model: string,
    leftButtons: {url: string, onClick?: () => void}[]; // URLs for left image buttons
    rightButtons: {url: string, onClick?: () => void}[]; // URLs for right image buttons,
    attachmentPoint: string;
    offsetGroupID: number;
}

interface Offset {
    x: number;
    y: number;
    z: number;
    rx: number;
    ry: number;
    rz: number;
}

const EquippedGear: React.FC<EquippedGearProps> = ({ project, model, leftButtons, rightButtons, attachmentPoint, offsetGroupID }) => {
    const [modelUrl, setModelUrl] = useState<string | null>(null);
    const [figureUrl, setFigureUrl] = useState<string | null>(null);
    const [offset, setOffset] = useState<Offset | null>(null);

    useEffect(() => {
        if (model) {
            resourceApi.getGeometryUrl(project, 'lego-universe:mesh/minifig/mf_main_lod.nif').then(setFigureUrl);
        }
    }, [project]);

    useEffect(() => {
        if (model) {
            resourceApi.getGeometryUrl(project, model).then(setModelUrl);
        }
    }, [model, project]);

    useEffect(() => {
        sqlApi.query(project, `SELECT Pos_X, Pos_Y, Pos_Z, Rot_X, Rot_Y, Rot_Z FROM AccessoryDefaultLoc WHERE GroupID = ${offsetGroupID}`).then((res) => {
            if (res.length > 0) {
                const row = res[0];
                setOffset({
                    x: Number(row['Pos_X']),
                    y: Number(row['Pos_Y']),
                    z: Number(row['Pos_Z']),
                    rx: Number(row['Rot_X']) * (Math.PI / 180),
                    ry: Number(row['Rot_Y']) * (Math.PI / 180),
                    rz: Number(row['Rot_Z']) * (Math.PI / 180)
                });
            }
        }).catch(error => {
            LogMessage(error, Severity.ERROR);
        });
    }, [offsetGroupID, project]);

    useEffect(() => {
        console.log("Model URL:", modelUrl, "Figure URL:", figureUrl, "Offset:", offset);
    }, [modelUrl, figureUrl, offset]);

    return (
        <div className="equipped-gear-container container-fluid">
            <h2 className="text-center text-white py-3">Equipped Gear</h2>
            <div className="row">
                {/* Left Column */}
                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                    {leftButtons.map((src, index) => (
                        <div key={index} className="gear-button mb-3"
                            onClick={src.onClick || (() => {})}>
                            <img className="gear-button-icon"
                                src={src.url}
                            />
                        </div>
                    ))}
                </div>

                {/* Center Column */}
                <div className="col-6">
                    <div className="w-100 h-100">
                        {modelUrl && figureUrl && offset && (
                            <ModelViewer modelUrl={figureUrl} attachments={[{
                                modelUrl: modelUrl,
                                attachmentPoint: attachmentPoint,
                                offset: new Vector3(offset.x, offset.y, offset.z),
                                rotation: new Vector3(offset.rx, offset.ry, offset.rz)
                            }]} />
                        )}
                    </div>
                </div>

                {/* Right Column */}
                <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                    {rightButtons.map((src, index) => (
                        <div key={index} className="gear-button mb-3"
                            onClick={src.onClick || (() => {})}>
                            <img className="gear-button-icon"
                                src={src.url}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EquippedGear;
