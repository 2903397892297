import { useCallback, useEffect, useRef } from 'react';
import {
    Row, Col
} from 'react-bootstrap';
import './components.css';
// States
import { useState } from 'react';

import Cookies from 'js-cookie';
import { GenericBtn } from './GenericBtn';
import { GenericInput } from './GenericInput';
import { GenericEnum } from './GenericEnum';
import { GenericText } from './GenericText';
import { projectApi, projectsApi } from './NejlikaApi';

interface NewProjectProps {
    card: boolean;
    onSave: (name: string) => void;
}

export const NewProject: React.FC<NewProjectProps> = ({ card, onSave }) => {
    const [projects, setProjects] = useState<string[]>([]);
    const [projectName, setProjectName] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        projectsApi.getProjects().then(data => {
            setProjects(Object.keys(data));
        });

        setError("Input a project name");
    }, []);

    const finishProject = useCallback(() => {
        setMessage("Creating project...");
        projectApi.createProject(projectName).then(success => {
            if (success) {
                onSave(projectName);
            }
            else {
                setError("Error creating project");
            }

            setMessage(null);
        });
    }, [projectName]);

    const validateProjectName = (name: string) => {
        setProjectName(name);

        // Require at least 3 characters
        if (name.length < 3) {
            setError("Project name must be at least 3 characters");
            return;
        }

        // Require only letters and underscores
        if (!name.match(/^[a-zA-Z_]+$/)) {
            setError("Project name can only contain letters and underscores");
            return;
        }

        // Can not already exist
        if (projects.includes(name)) {
            setError("Project already exists");
            return;
        }

        // No errors
        setError(null);
    }

    return (
        <div>
            <Row className='mp-0 border-bottom'>
                <Col md={card ? 12 : 3}>
                    <GenericInput title='Name' type="text" value={projectName} setValue={validateProjectName} />
                </Col>
            </Row>
            <Row>
                {message &&
                <Col md={12} className='center-text'>
                    <p className='yellow-text'>{message}</p>
                </Col>}
                {error &&
                <Col md={12} className='center-text'>
                    <p className='red-text'>{error}</p>
                </Col>}
                {!error &&
                <Col md={12} className='center-text'>
                    <GenericBtn title='Save' onClick={finishProject} />
                </Col>
                }
            </Row>
        </div>
    );
}