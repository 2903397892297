import { useCallback, useEffect, useRef } from 'react';
import {
    Row, Col
} from 'react-bootstrap';
import './components.css';
// States
import { useState } from 'react';

import Cookies from 'js-cookie';
import { GenericBtn } from './GenericBtn';
import { GenericInput } from './GenericInput';
import { GenericEnum } from './GenericEnum';
import { GenericText } from './GenericText';
import { FormatEnumOption } from './Formatters';
import { modApi, packageApi, projectApi, typeApi } from './NejlikaApi';

interface NewModProps {
    project: string;
    card: boolean;
    save: (value: any) => void;
}

export const NewMod: React.FC<NewModProps> = ({ project, card, save }) => {
    const [packs, setPacks] = useState<string[] | null>([]);
    const [selectedPack, _setSelectedPack] = useState<string>(Cookies.get('package') || "");
    const [modTypes, setModTypes] = useState<string[]>([]);

    const [modName, setModName] = useState<string>("");
    const [modType, setModType] = useState<string>("object");
    const [valid, setValid] = useState<boolean>(false);

    const packRef = useRef<string>(selectedPack);

    const setSelectedPack = (pack: string) => {
        _setSelectedPack(pack);
        packRef.current = pack;
    };

    const updateEntries = useCallback(() => {
        packageApi.getPackages(project).then(data => {
            setPacks(data);
        });

        if (!packRef.current) {
            return;
        }
    }, [project]);

    useEffect(() => {
        updateEntries();
    }, [selectedPack]);

    // Fetch the list every 5 seconds
    useEffect(() => {
        // Fetch the cookie if it exists
        const cookie = Cookies.get('package');

        setModName(cookie + ":");

        if (cookie) {
            setSelectedPack(cookie);

            updateEntries();
        }
    
        const interval = setInterval(() => {
            updateEntries();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        typeApi.getTypes(project).then(data => {
            setModTypes(data);
        });
    }, [project]);

    const onSelectedPackChange = (pack: string) => {
        setSelectedPack(pack);

        if (!modName.startsWith("lego-universe:"))
        {
            // Replace what comes before : with the new pack
            setModName(pack + ":" + modName.substring(modName.indexOf(':') + 1));
        }
    }

    const finishMod = useCallback(() => {
        let template: any = {
            "name": modName,
            "type": modType
        }

        modApi.createMod(project, template).then(data => {
            save(data);
        });
    }, [modName, modType, project, save]);

    const setName = useCallback((name: string) => {
        setModName(name);

        // It must begin with the pack name, followed by a colon
        if (!name.startsWith(selectedPack + ":"))
        {
            setValid(false);
        }
        else
        {
            setValid(true);
        }
    }, [selectedPack]);

    return (
        <div>
            <Row className='mp-0 border-bottom'>
                <Col md={card ? 12 : 3}>
                    <GenericEnum title='Package' options={packs || []} value={selectedPack} setValue={onSelectedPackChange} />
                </Col>
                <Col md={card ? 12 : 3}>
                    <GenericInput title='Name' type="text" value={modName} setValue={setName} />
                </Col>
                <Col md={card ? 12 : 3}>
                    {modTypes.length > 0 &&
                    <GenericEnum title='Type' options={modTypes} value={modType} setValue={setModType} 
                        formatOption={(option) => FormatEnumOption(option)}
                    />
                    }
                    {modTypes.length === 0 &&
                    <GenericText title='Fetching...' />
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12} className='center-text'>
                    <GenericBtn id="create-mod-save" title='Save' primary={true} onClick={finishMod} readonly={!valid} />
                </Col>
                {!valid && <Row className='center-text p-2'>
                    <Col md={12}>
                        <p className='log-error'>The mod name must begin with the package name followed by a colon</p>
                    </Col>
                </Row>}
            </Row>
        </div>
    );
}