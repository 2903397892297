import { useEffect } from 'react';

import './components.css';
import { ModExplorerEntry } from './ModExplorerEntry';
// States
import { useState } from 'react';

import Cookies from 'js-cookie';
import { authApi } from './NejlikaApi';
import axios from 'axios';

export const Auth = () => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [error, setError] = useState<string | null>(null);
    let sent = false;

    // Get the 'code' from the URL

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
    
        const code = urlParams.get('code');

        if (code && !sent) {
            sent = true;
            authApi.authenticate(code).then(data => {
                const token = data;

                // Add a cookie, set the SameSite attribute to 'None', share with all subdomains, and set the Secure attribute
                Cookies.set('token', token, { expires: 3600, path: '/', domain: 'nejlika.org', sameSite: 'none', secure: true });

                setSuccess(true);

                window.location.href = '/editor';
            }).catch(err => {
                if (axios.isAxiosError(err)) {
                    setError(err.response?.data);
                }
                else {
                    setError(`An unknown error occurred: ${err}`);
                }
            });
        }
        else {
            setSuccess(false);
        }
    }, []);

    return (
        <div id='explorer-container'>
            {error && <h1 className='wheat-text'>{error}</h1>}
            {!error && success === null && <h1 className='wheat-text'>Authenticating...</h1>}
        </div>
    );
}