import React, { useEffect, useState } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { InviteUser } from './InviteUser';

interface InviteUserCardProps {
    onClose: () => void;
}

export const InviteUserCard: React.FC<InviteUserCardProps> = ({ onClose }) => {
    const [show, setShow] = useState<boolean>(true);

    return (
        <Modal show={show} onHide={() => {
            setShow(false);
            onClose();
        }}>
            <Card className='vs-bg p-2'>
                <InviteUser onDone={onClose} />
            </Card>
        </Modal>
    );
};