import { useParams } from 'react-router-dom';
import { WorldViewer } from './WorldViewer';
import Cookies from 'js-cookie';

export const WorldEmbedded = () => {
    const { zone } = useParams<{ zone: string }>();

    if (!zone) {
        return null;
    }

    const project = Cookies.get('project') || "default";

    return (
        <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
            <WorldViewer project={project} zone={zone} />
        </div>
    );
}