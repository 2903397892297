import { EditorMode } from "./EditorMode";

// Define actions for the reducer
type InterfaceUserAction =
    | { type: "SET_GUI_ENABLED"; payload: EditorMode }
    | { type: "TOGGLE_SHOW"; key: keyof InterfaceUserSettings }
    | { type: "SET_PROJECT"; payload: string };

// Initial state
export const InitialUserSettingsState: InterfaceUserSettings = {
    guiEnabled: "graphical",
    showUnity: false,
    showEditor: true,
    showExplorer: true,
    showLogger: true,
    showGuide: true,
    showResources: true,
    showDatabase: false,
    project: "default"
};

export interface InterfaceUserSettings {
    guiEnabled: EditorMode;
    showUnity: boolean;
    showEditor: boolean;
    showExplorer: boolean;
    showLogger: boolean;
    showGuide: boolean;
    showResources: boolean;
    showDatabase: boolean;
    project: string;
}

// Reducer function
export function interfaceUserSettingsReducer(state: InterfaceUserSettings, action: InterfaceUserAction): InterfaceUserSettings {
    switch (action.type) {
        case "SET_GUI_ENABLED":
            return { ...state, guiEnabled: action.payload };
        case "TOGGLE_SHOW":
            return { ...state, [action.key]: !state[action.key] };
        case "SET_PROJECT":
            return { ...state, project: action.payload };
        default:
            return state;
    }
}

export interface InterfaceUserSettingsProps {
    userSettings: InterfaceUserSettings;
    dispatchUserSettings: React.Dispatch<InterfaceUserAction>;
}