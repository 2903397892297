import React, { useEffect, useState } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { NewMod } from './NewMod';

interface CreateModCardProps {
    project: string;
    save: (value: any) => void;
    onClose: () => void;
}

export const CreateModCard: React.FC<CreateModCardProps> = ({ project, save, onClose }) => {
    const [show, setShow] = useState<boolean>(true);

    return (
        <Modal show={show} onHide={() => {
            setShow(false);
            onClose();
        }}>
            <Card className='vs-bg p-2'>
                <NewMod project={project} card={true} save={save} />
            </Card>
        </Modal>
    );
};