import React, { useCallback } from 'react';
import logo from './logo.svg';
import {
    Card,
    Button,
    Row
} from 'react-bootstrap';
import './components.css';
import { useState } from 'react';
import { Popup } from './Popup';
import useMousePosition from './Mouse';
import { OpenFile } from './OpenFile';
import { LogMessage, Severity } from './Logger';
import { authorization, Permission } from '../Authorization';
import { modApi } from './NejlikaApi';

interface EntryProps {
    project: string;
    name: string;
    isResource?: boolean;
    openEditors: OpenFile[];
    setOpenEditors: (openEditors: OpenFile[]) => void;
}

export const ModExplorerEntry: React.FC<EntryProps> = ({ project, name, isResource, openEditors, setOpenEditors }) => {
    const canWriteMods = authorization.hasPermission(Permission.WriteMods);
    const canReadMods = authorization.hasPermission(Permission.ReadMods);

    const onModValueSet = useCallback((uniqueId: number, value: any, openFile: OpenFile) => {
        if (!canWriteMods) {
            return;
        }

        console.log("Setting value [ModExplorerEntry]: ", value);
        modApi.updateMod(project, uniqueId.toString(), value).then(data => {
            if (!data) {
                LogMessage("Error updating mod", Severity.ERROR);
            }
        }).catch(err => {
            LogMessage("Error updating mod", Severity.ERROR);
            LogMessage(err, Severity.ERROR);
        });
    }, [project]);

    const handleClick = useCallback(() => {
        if (!canReadMods) {
            return;
        }

        modApi.getMod(project, name).then(data => {
            const { json, uid } = data;
            const openFile = openEditors.find(file => file.uniqueId === uid.toString());
            if (openFile) {
                return;
            }
            const newOpenEditors = [...openEditors];

            const newOpenFile: OpenFile = { 
                title: name,
                value: json,
                uniqueId: uid.toString(),
                setValue: (value: any) => {
                    newOpenFile.value = value;
                    onModValueSet(uid, value, newOpenFile);
                },
                scope: [],
                isFile: true
            }

            newOpenEditors.push(newOpenFile);
            setOpenEditors(newOpenEditors);
        })
    }, [canReadMods, openEditors, onModValueSet, project, name, setOpenEditors]);

    return (
        <Row className={`explorer-entry explorer-card mp-0`} onClick={handleClick}>
            <p className='wheat-text pointer'>{name}</p>
        </Row>
    );
}
