import React, { useCallback, useState } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { projectApi } from '../NejlikaApi';
import { authorization } from '../../Authorization';

interface IntroductionModalProps {
    show: boolean;
    onHide: () => void;
    onCreateProject: () => void;
}

const IntroductionModal: React.FC<IntroductionModalProps> = ({ show, onHide, onCreateProject }) => {
    const handleCreateProject = useCallback(() => {
        const identity = authorization.getName();

        projectApi.createProject(identity);

        onCreateProject();
    }, [onCreateProject]);

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Welcome to the editor!</p>
                    <p>Let's start by creating a personal project for you.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={12} className='mx-auto d-block w-100'>
                            <Button variant="primary" className='mx-auto d-block' onClick={handleCreateProject} style={{ width: '90%' }}>
                                Create Project
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default IntroductionModal;