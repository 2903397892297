import React, { useEffect, useState, useCallback, useRef } from 'react';
import './components.css';
import {
    Row,
    Col
} from 'react-bootstrap';
import { PropEntry } from './PropEntry';
import { ArrayPropEntry } from './ArrayPropEntry';
import { BehaviorRoot } from './nodes/BehaviorRoot';
import { Behavior } from './nodes/Behavior';
import { BehaviorEdge } from './nodes/BehaviorEdge';

import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    BackgroundVariant,
    Edge,
    Node
} from 'reactflow';

import 'reactflow/dist/style.css';

interface BehaviorGuiProps {
    value: any;
    setValue: (value: any, name: string | null) => void;
    title: string;
    name: string | null;
    parent: any | null;
}

const nodeTypes = {
    behaviorRoot: BehaviorRoot,
    behavior: Behavior
};

const edgeTypes = {
    behaviorEdge: BehaviorEdge
};

export const BehaviorGui: React.FC<BehaviorGuiProps> = ({ value, setValue, title, name, parent }) => {

    //
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [internalReactFlowInstance, internalSetReactFlowInstance] = useState<any>(null);
    //const [selectedId, setSelectedId] = useState<string | null>(null);
    //const [selectedHandle, setSelectedHandle] = useState<string | null>(null);
    let selectedId: string | null = null;
    let selectedHandle: string | null = null;
    let tNodes: any = [];
    let tEdges: any = [];

    let reactFlowInstance: any;

    if (value["values"] === undefined) {
        value["values"] = {};
    }

    const setReactFlowInstance = (instance: any) => {
        reactFlowInstance = instance;
        internalSetReactFlowInstance(instance);
    }

    const findBehavior: any = (name: string) => {
        if (name === "root") {
            return value;
        }

        // Search in the behaviors
        if (!value["values"]) {
            return null;
        }

        let behaviors = value["values"]["behaviors"];

        if (!behaviors) {
            return null;
        }

        for (let i = 0; i < behaviors.length; i++) {
            if (behaviors[i].name === name) {
                return behaviors[i];
            }
        }
    };

    const setBehaviorValue = (name: string, behvaior: any) => {
        if (name === "root") {
            throw new Error("Cannot set the root behavior");
        } else {
            let behaviors = value["values"]["behaviors"];

            for (let i = 0; i < behaviors.length; i++) {
                if (behaviors[i].name === name) {
                    behaviors[i] = behvaior;

                    setValue(value, name);
                    break;
                }
            }
        }
    }

    const addBehaviorNode = (
        tmpNodes: any, tmpEdges: any, behaviorValue: any, overrideX?: number, overrideY?: number
    ) => {
        console.log("Adding behavior node: " + behaviorValue.name)

        if (!behaviorValue.values) {
            console.log("Behavior has no values: " + behaviorValue.name);
            return;
        }
        
        let x = overrideX;
        if (x === undefined && behaviorValue["extra-data"] && behaviorValue["extra-data"]["position"]) {
            x = behaviorValue["extra-data"]["position"]["x"];
        } else if (x === undefined) {
            x = 0;
        } else {
            behaviorValue["extra-data"] = behaviorValue["extra-data"] || {};
            behaviorValue["extra-data"]["position"] = behaviorValue["extra-data"]["position"] || {};
            behaviorValue["extra-data"]["position"]["x"] = x;
        }
        
        let y = overrideY;
        if (y === undefined && behaviorValue["extra-data"] && behaviorValue["extra-data"]["position"]) {
            y = behaviorValue["extra-data"]["position"]["y"];
        } else if (y === undefined) {
            y = 0;
        } else {
            behaviorValue["extra-data"] = behaviorValue["extra-data"] || {};
            behaviorValue["extra-data"]["position"] = behaviorValue["extra-data"]["position"] || {};
            behaviorValue["extra-data"]["position"]["y"] = y;
        }

        tmpNodes.push({ id: behaviorValue.name, type: 'behavior', data: { value: behaviorValue, setValue: (val: any) => {
            setBehaviorValue(behaviorValue.name, val);
        }, redraw: () => {
        } }, position: { x: x, y: y } });

        for (let prop in behaviorValue.values["parameters"]) {
            const val = behaviorValue.values["parameters"][prop];
            const type = typeof val;

            console.log("Checking prop: " + prop + " with type: " + type);

            if (type === "object") {
                throw new Error("Object type not supported");
            }
            else if (type === "string") {
                if (val === "lego-universe:0") {
                    continue;
                }
                
                const nodeId = behaviorValue.name + "-to-" + val + "-with-" + prop;

                console.log("Adding edge: " + nodeId);

                // Check if there are any other parameters with the same source and target
                const siblings = Object.values(behaviorValue.values["parameters"]).filter((p: any) => p === val).length;
                const siblingNr = Object.keys(behaviorValue.values["parameters"]).filter((p: any) => behaviorValue.values["parameters"][p] === val).indexOf(prop);

                tmpEdges.push({ 
                    id: nodeId,
                    target: val,
                    source: behaviorValue.name,
                    type: 'behaviorEdge',
                    data: { name: prop, siblings: siblings, nr: siblingNr },
                    sourceHandle: "out"
                });
            }
        }
    };

    useEffect(() => {
        let nd: any = [];
        let ed: any = [];

        let x = value["extra-data"] && value["extra-data"]["position"] ? value["extra-data"]["position"]["x"] : 0;
        let y = value["extra-data"] && value["extra-data"]["position"] ? value["extra-data"]["position"]["y"] : 0;

        nd.push({ id: 'root', type: 'behaviorRoot', data: { value: value, setValue: (val: any) => {
            setValue(value, name);
        } }, position: { x: x, y: y } })

        let behavior = value.values["behavior"];
        let behaviorName: null | string;

        if (!behavior) {
            behaviorName = null;
        }
        else if (typeof behavior === "object") {
            let values = value["values"];

            if (!values) {
                values = {};
            }

            let behaviors = values["behaviors"];

            if (!behaviors) {
                behaviors = [];
            }

            behaviors.push(behavior);

            values["behaviors"] = behaviors;

            value.values["behavior"] = behavior.name;

            moveToBehaviorList(behavior);

            behaviorName = behavior.name;
        }
        else {
            behaviorName = behavior;
        }

        setValue(value, name);

        // Add the behaviors
        let behaviors = value.values["behaviors"];

        if (behaviors) {
            for (let i = 0; i < behaviors.length; i++) {
                addBehaviorNode(nd, ed, behaviors[i]);
            }
        }

        // Add edge connecting the root to the behavior
        if (behaviorName) {
            ed.push({ id: 'root-to-behavior', target: behaviorName, source: 'root', type: 'behaviorEdge', data: { name: 'root' }, sourceHandle: 'root' });
        }

        setNodes(nd);
        setEdges(ed);

        tNodes = nd;
        tEdges = ed;
    }, []);

    const moveToBehaviorList = (behavior: any) => {
        let values = value["values"];

        if (!values) {
            values = {};
        }

        let behaviors = values["behaviors"];

        if (!behaviors) {
            behaviors = [];
        }

        // Check that it's not already in the list
        if (!behaviors.find((b: any) => b.name === behavior.name)) {
            behaviors.push(behavior);

            values["behaviors"] = behaviors;
        }

        // Check if the behavior has any children
        for (let key in behavior.values["parameters"]) {
            const type = typeof behavior.values["parameters"][key];
            if (type === "object") {
                const behaviorValue = behavior.values["parameters"][key];

                // Set the parameter to be the name of the target behavior
                behavior.values["parameters"][key] = behaviorValue.name;

                moveToBehaviorList(behaviorValue);
            }
        }
    };

    const onConnect = useCallback(
        (params: any) => setEdges((eds) => {
            const isRoot = params.source === "root";

            const behavior = findBehavior(isRoot ? "root" : params.source, value);

            if (!behavior) {
                alert("Could not find the source behavior: " + params.source);
                return eds;
            }

            const selectedOutput = isRoot ? "root" : behavior["extra-data"]["selected-output"];

            console.log(params)
            console.log(eds)
            params.id = params.source + "-to-" + params.target + "-with-" + selectedOutput;
            params.type = 'behaviorEdge';
            params.data = { name: selectedOutput };
            // Do not allow self-connections
            if (params.source === params.target) {
                return eds;
            }

            tEdges.push(params);

            // Make sure there is only one edge from this sourceHandle
            const edge = eds.find((e: Edge<any>) => e.source === params.source && e.data.name === selectedOutput);
            // Remove the existing edge
            if (edge) {
                eds = eds.filter((e: any) => e.id !== edge.id);
                console.log("Removing edge: " + edge.id);
                tEdges = tEdges.filter((e: any) => e.id !== edge.id);
            }
            else {
                console.log("No edge found for source: " + params.source + " and handle: " + selectedOutput);
            }

            // Set the parameter to be the name of the target behavior
            if (selectedOutput === "root") {
                behavior.values["behavior"] = params.target;
            }
            else {
                behavior.values["parameters"][selectedOutput] = params.target;
            }

            // Update the value
            setValue(value, name);

            return addEdge(params, eds); 
        }),
        [setEdges]
    );

    const onConnectEnd = useCallback((params: any) => {
        if (params.target.className !== 'react-flow__pane' || !selectedId || !selectedHandle) {
            return;
        }

        console.log('onConnectEnd', selectedId, selectedHandle, params);

        // Create a new node
        let nd: any = tNodes;
        let ed: any = tEdges;

        // Generate a new behavior name
        let behaviorName = value.name + ":behavior-" + Math.floor(Math.random() * 1000000);

        let behaviorValue = {
            name: behaviorName,
            type: 'behavior',
            actions: 'add',
            values: {
                "behavior-type": "And",
                "parameters": {
                    "test": 0
                }
            }
        };

        // Add the new behavior to the parent
        if (selectedHandle === "root") {
            value.values["behavior"] = behaviorName;
        } else {
            // Find the selected behavior
            let selectedBehavior = findBehavior(selectedId, value);

            if (!selectedBehavior) {
                alert("Could not find the selected behavior");
                return;
            }

            selectedBehavior.values["parameters"][selectedHandle] = behaviorName;
        }

        // Add the new behavior to the value
        moveToBehaviorList(behaviorValue);

        // Update the value
        setValue(value, name);

        console.log(nd, ed);

        // Make sure there is only one edge from this sourceHandle
        const edge = ed.find((e: Edge<any>) => e.source === selectedId && e.data.name === selectedHandle);

        // Remove the existing edge
        if (edge) {
            ed = ed.filter((e: Edge<any>) => e.id !== edge.id);
            console.log("Removing edge: " + edge.id);
        }
        else {
            console.log("No edge found for source: " + selectedId + " and handle: " + selectedHandle);
        }

        const position = reactFlowInstance.screenToFlowPosition({
            x: params.clientX,
            y: params.clientY
        });

        // Add the new node
        addBehaviorNode(nd, ed, behaviorValue, position.x - 40, position.y - 40);

        // Add the new edge
        ed.push({ id: selectedId + "-to-" + behaviorName + "-with-" + selectedHandle, target: behaviorName, source: selectedId, type: 'behaviorEdge', data: { name: selectedHandle }, sourceHandle: "out" });

        tNodes = nd;
        tEdges = ed;

        console.log(nd, ed);

        setNodes(nd);
        setEdges(ed);
    }, []);

    const onMoveEnd: any = useCallback((params: any, viewport: any) => {
        let extraData = value["extra-data"];

        if (!extraData) {
            extraData = {};
        }

        extraData["viewport"] = viewport;

        value["extra-data"] = extraData;

        setValue(value, name);
    }, []);

    const onConnectStart = useCallback((params: any) => {
        // The target is an element, get the data-id attribute and set it as the selectedId
        let j = JSON.parse(JSON.stringify(params.target.dataset));
        
        console.log(j);

        //setSelectedId(j.nodeid);
        //setSelectedHandle(j.handleid);
        selectedId = j.nodeid;

        const behavior = findBehavior(j.nodeid, value);

        if (!behavior) {
            alert("Could not find the behavior: " + j.nodeid);
            return;
        }

        if (behavior.name === "root") {
            selectedHandle = "root";
        } else {
            selectedHandle = behavior["extra-data"]["selected-output"];
        }

        console.log('onConnectStart', selectedId, selectedHandle, params);
    }, []);

    const onNodeDragStop = useCallback((event: any, node: any, nodes: any) => {
        let behavior = node.id === "root" ? value : findBehavior(node.id, value);

        if (!behavior) {
            return;
        }

        let extraData = behavior["extra-data"];

        if (!extraData) {
            extraData = {};
        }

        extraData["position"] = { x: node.position.x, y: node.position.y };

        behavior["extra-data"] = extraData;

        // Update the value in the tNodes
        for (let i = 0; i < tNodes.length; i++) {
            if (tNodes[i].id === node.id) {
                tNodes[i].position = node.position;
            }
        }

        setValue(value, name);
    }, []);

    const onNodesDelete = useCallback((nodes: any) => {
        // Remove the nodes from the behavior list, and remove the edges that connect to them
        let nd: any = tNodes;
        let ed: any = tEdges;

        console.log(nd, ed);

        for (let i = 0; i < nodes.length; i++) {
            let behavior = nodes[i].id === "root" ? value : findBehavior(nodes[i].id, value);

            if (nodes[i].id === "root") {
                alert("Cannot delete the root behavior");
                continue;
            }

            if (!behavior) {
                alert("Could not find the behavior: " + nodes[i].id);
                continue;
            }

            // Remove the behavior from the list
            let behaviors: any[] = value["values"]["behaviors"];

            for (let j = 0; j < behaviors.length; j++) {
                if (behaviors[j].name === nodes[i].id) {
                    behaviors.splice(j, 1);
                    break;
                }
            }

            // Loop through the behaviors and remove any references to the deleted behavior
            for (let j = 0; j < behaviors.length; j++) {
                for (let prop in behaviors[j].values["parameters"]) {
                    if (behaviors[j].values["parameters"][prop] === nodes[i].id) {
                        delete behaviors[j].values["parameters"][prop];
                    }
                }
            }

            // Remove the edges
            //ed = ed.filter((e: any) => e.source !== nodes[i].id && e.target !== nodes[i].id);
            for (let j = 0; j < ed.length; j++) {
                if (ed[j].source === nodes[i].id || ed[j].target === nodes[i].id) {
                    console.log("Removing edge: " + ed[j].id);
                    ed.splice(j, 1);
                    j--;
                }
            }

            // Remove the node
            nd = nd.filter((n: any) => n.id !== nodes[i].id);
        }

        tNodes = nd;
        tEdges = ed;

        console.log(nd, ed);

        setNodes(nd);
        setEdges(ed);

        setValue(value, name);
    }, []);

    const onEdgesDelete = useCallback((edges: any) => {
        let ed: any = tEdges;

        for (let i = 0; i < edges.length; i++) {
            console.log("Removing edge: " + edges[i].id);
            ed = ed.filter((e: Edge<any>) => e.id !== edges[i].id);
        }

        // Remove the edges from the behavior list
        for (let i = 0; i < edges.length; i++) {
            let source = edges[i].source;
            let sourceHandle = edges[i].data.name;

            console.log("Removing edge: " + source + " to " + edges[i].target + " with " + sourceHandle);
            
            let behavior = source === "root" ? value : findBehavior(source, value);

            if (!behavior) {
                alert("Could not find the behavior: " + source);
                continue;
            }

            if (sourceHandle === "root") {
                behavior.values["behavior"] = null;
            } else {
                delete behavior.values["parameters"][sourceHandle];
            }
        }

        tEdges = ed;

        setValue(value, name);

        setEdges(ed);
    }, []);

    return (
        <div className='node-editor'>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onConnectEnd={onConnectEnd}
                onConnectStart={onConnectStart}
                onMoveEnd={onMoveEnd}
                onNodeDragStop={onNodeDragStop}
                onInit={setReactFlowInstance}
                onNodesDelete={onNodesDelete}
                onEdgesDelete={onEdgesDelete}
                defaultViewport={value["extra-data"] ? value["extra-data"]["viewport"] : undefined}
            >
                <Controls />
                <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
            </ReactFlow>
        </div>
    )
}