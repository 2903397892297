import React, { useEffect, useRef, useState, useCallback } from "react";
import {
    Engine,
    Scene,
    SceneLoader,
    ArcRotateCamera,
    HemisphericLight,
    Vector3,
    AnimationGroup,
    Color4
} from "@babylonjs/core";
import "@babylonjs/loaders"; // Ensures GLTF loader is included

interface Attachment {
    modelUrl: string;
    attachmentPoint: string;
    offset: Vector3;
    rotation: Vector3;
}

interface ModelViewerProps {
    modelUrl: string;
    onAnimationsLoaded?: (animations: string[]) => void; // Callback for animation list
    animationToPlay?: string; // Animation name to play
    transitionDuration?: number; // Transition duration in seconds
    clearScene?: boolean; // Clear the scene before loading the model
    attachments?: Attachment[]; // Attachments to add to the model
}

export const ModelViewer: React.FC<ModelViewerProps> = ({
    modelUrl,
    onAnimationsLoaded,
    animationToPlay,
    clearScene = true,
    attachments = []
}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [engine, setEngine] = useState<Engine | null>(null);
    const [scene, setScene] = useState<Scene | null>(null);
    const [camera, setCamera] = useState<ArcRotateCamera | null>(null);
    const animationGroupsRef = useRef<AnimationGroup[]>([]);

    useEffect(() => {
        if (canvasRef.current) {
            const newEngine = new Engine(canvasRef.current, true);
            const newScene = new Scene(newEngine);
            setEngine(newEngine);
            setScene(newScene);

            // Setup camera and lights
            const camera = new ArcRotateCamera(
                "camera",
                Math.PI / 2,
                Math.PI / 3,
                10,
                new Vector3(0, 1, 0),
                newScene
            );
            camera.attachControl(canvasRef.current, true);

            setCamera(camera);

            const light = new HemisphericLight("light1", new Vector3(0, 1, 0), newScene);
            light.intensity = 0.7;

            // Set background to transparent
            newScene.clearColor = new Color4(0, 0, 0, 0);

            // Render loop
            newEngine.runRenderLoop(() => {
                newScene.render();
            });

            const resize = () => {
                newEngine.resize();
            }

            // Resize the engine on window resize
            if (window) {
                window.addEventListener("resize", resize);
            }

            // Capture the scroll wheel event to prevent page scrolling
            const preventScroll = (event: WheelEvent) => {
                event.preventDefault();
            }

            if (canvasRef.current) {
                canvasRef.current.addEventListener("wheel", preventScroll);
            }

            // Cleanup
            return () => {
                newScene.dispose();
                newEngine.dispose();

                if (window) {
                    window.removeEventListener("resize", resize);
                }

                if (canvasRef.current) {
                    canvasRef.current.removeEventListener("wheel", preventScroll);
                }
            };
        }
    }, []);

    const loadAttachment = useCallback((attachment: Attachment) => {
        if (scene) {
            SceneLoader.ImportMeshAsync("", attachment.modelUrl, "", scene).then((result) => {
                const attachmentMesh = result.meshes[0];
                // Attachment point can be any node in the scene
                const attachmentPoint = scene.getNodeByName(attachment.attachmentPoint);
                if (attachmentPoint) {
                    attachmentMesh.parent = attachmentPoint;
                    attachmentMesh.position = attachment.offset;
                    attachmentMesh.rotation = attachment.rotation;
                }
                else {
                    console.error("Attachment point not found:", attachment.attachmentPoint);
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [scene]);

    const loadModel = useCallback((modelUrl: string) => {
        if (scene) {
            if (clearScene) {
                scene.meshes.forEach((mesh) => mesh.dispose());
            }
    
            SceneLoader.AppendAsync("", modelUrl, scene).then(() => {
                // Extract animation names
                const animationNames = scene.animationGroups.map((group) => group.name);
                if (onAnimationsLoaded) {
                    onAnimationsLoaded(animationNames);
                }
                
                // Play the first animation
                if (animationNames.length > 0) {
                    animationGroupsRef.current = scene.animationGroups;
                    animationGroupsRef.current[0].play(true); // Loop the animation
                }

                // Set camera target to the model
                if (camera && scene.meshes.length > 0) {
                    camera.setTarget(scene.meshes[0]);
                }
                
                // Load attachments
                attachments.forEach((attachment) => loadAttachment(attachment));
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [scene, onAnimationsLoaded, camera, clearScene, attachments]);

    useEffect(() => {
        if (scene && modelUrl) {
            if (scene.isReady()) {
                loadModel(modelUrl);
            }
            else {
                scene.onReadyObservable.addOnce(() => {
                    loadModel(modelUrl);
                });
            }
        }
    }, [scene, modelUrl]);    

    useEffect(() => {
        if (animationToPlay && animationGroupsRef.current.length > 0) {
            const animationGroup = animationGroupsRef.current.find(
                (group) => group.name === animationToPlay
            );
            if (animationGroup) {
                // Stop other animations and play selected one
                animationGroupsRef.current.forEach((group) => group.stop());
                animationGroup.play(true); // Loop the animation
            }
        }
    }, [animationToPlay]);

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />
        </div>
    );
};
