import { useCallback, useEffect, useRef } from 'react';
import {
    Row, Col
} from 'react-bootstrap';
import './components.css';
import { ModExplorerEntry } from './ModExplorerEntry';
// States
import { useState } from 'react';

import Cookies from 'js-cookie';
import { GenericBtn } from './GenericBtn';
import { CreateModPackCard } from './CreateModPackCard';
import { OpenFile } from './OpenFile';
import { GuideHandler, GuideHandlers } from './Guide/GuideHandler';
import { packageApi, PackageData } from './NejlikaApi';

interface ExplorerProps {
    project: string;
    openEditors: OpenFile[];
    setOpenEditors: (openEditors: OpenFile[]) => void;
}

export const Explorer: React.FC<ExplorerProps> = ({ project, openEditors, setOpenEditors }) => {
    const [packs, setPacks] = useState<string[] | null>([]);
    const [selectedPack, _setSelectedPack] = useState<string>(Cookies.get('package') || "");
    const [entries, setEntries] = useState<string[] | null>([]);
    const [resources, setResources] = useState<string[] | null>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showCreateModPack, setShowCreateModPack] = useState<boolean>(false);
    const [modPackValue, setModPackValue] = useState<PackageData>({
        name: "",
        version: "",
        description: "",
        author: "",
        mods: []
    });
    const [isEditingPack, setIsEditingPack] = useState<boolean>(false);

    const packRef = useRef<string>(selectedPack);

    const setSelectedPack = useCallback((pack: string) => {
        _setSelectedPack(pack);
        packRef.current = pack;
    }, []);

    const updateEntries = useCallback(() => {
        packageApi.getPackages(project).then(data => {
            setPacks(data);

            if (!data.includes(packRef.current)) {
                setSelectedPack(data[0]);

                // Update cookie
                Cookies.set('package', data[0], { expires: 7, path: '', sameSite: 'strict' });
            }
        });

        setLoading(true);

        if (!packRef.current) {
            return;
        }

        // Use a POST request to get the list of files
        // 'localhost:8080/api/pack'
        packageApi.getPackage(project, packRef.current).then(data => {
            // Update the state
            setEntries(data.mods);

            setLoading(false);
        });

    }, [project]);

    useEffect(() => {
        updateEntries();
    }, [selectedPack, updateEntries]);

    // Fetch the list every 5 seconds
    useEffect(() => {
        // Fetch the cookie if it exists
        const cookie = Cookies.get('package');

        if (cookie) {
            setSelectedPack(cookie);

            collectModPackInformation(cookie);

            updateEntries();
        }
    
        const interval = setInterval(() => {
            updateEntries();
        }, 2500);

        return () => clearInterval(interval);
    }, [updateEntries]);

    const collectModPackInformation = useCallback((pack: string) => {
        packageApi.getPackage(project, pack).then(data => {
            setModPackValue(data);
        });
    }, [project]);

    const onSelectedPackChange = useCallback((pack: string) => {
        // Set a cookie with the selected pack
        Cookies.set('package', pack, { expires: 7, path: '', sameSite: 'strict' });

        setSelectedPack(pack);

        collectModPackInformation(pack);
    }, [collectModPackInformation]);

    const onFinishEditModPack = useCallback((value: any) => {
        packageApi.updatePackage(project, modPackValue.name, {
            version: value.version,
            description: value.description,
            author: value.author
        }).then(data => {
            if (!data) {
                alert("Error updating mod pack");
                return;
            }

            setShowCreateModPack(false);
            setModPackValue(value);

            setSelectedPack(value.name);

            updateEntries();

            setIsEditingPack(false);
        });
    }, [modPackValue, project, updateEntries]);

    const onEditModPack = useCallback(() => {
        packageApi.getPackage(project, selectedPack).then(data => {
            setModPackValue(data);

            setShowCreateModPack(true);

            setIsEditingPack(true);
        });
    }, [selectedPack, project]);

    return (
        <div id='explorer-container'>
            <div className='wh-100'>
                <div className='explorer-inputs'>
                    <Row className='mp-0 pb-2'>
                        <Col md={12}>
                            <select id="package-selector" className='form-select vs-bg generic-input wheat-text' onChange={(e) => onSelectedPackChange(e.target.value)} value={selectedPack || ""}>
                                {packs && packs.map((pack, index) => {
                                    return <option className='vs-bg generic-input wheat-text' key={index} value={pack}>{pack}</option>;
                                })}
                            </select>
                        </Col>
                    </Row>
                    <Row className='mp-0 pb-2'>
                        <Col md={12}>
                            <GenericBtn id="edit-package" onClick={() => onEditModPack()} title='Edit Package' />
                        </Col>
                    </Row>
                </div>
                <div className='explorer-mods'>
                    <Row className='scrollable-h-100 p-2' id="package-files">
                        {entries && entries.map((entry, index) => {
                            return <ModExplorerEntry
                                project={project}
                                key={index}
                                name={entry}
                                isResource={false}
                                openEditors={openEditors}
                                setOpenEditors={setOpenEditors}
                            />;
                        })}
                        {entries?.length == 0 && <p className='wheat-text'>No files found</p>}
                    </Row>
                </div>
                <GuideHandlers elements={['package-selector', 'edit-package', 'browse-resources', 'package-files']} />
            </div>
            {showCreateModPack &&
                <CreateModPackCard value={modPackValue} isEditing={isEditingPack} save={(value) => {
                    onFinishEditModPack(value);
                } } onClose={() => {
                    setShowCreateModPack(false);
                }} />
            }
       </div>
    );
}