import { Col, Row } from "react-bootstrap";
import { FormatEnumOption } from "../../Formatters";
import { GenericEnum } from "../../GenericEnum";
import { GenericError } from "../../GenericError";
import { GenericIcon } from "../../GenericIcon";
import { GenericInput } from "../../GenericInput";
import { TypeTemplateWrapper } from "../../graphical/TypeTemplateWrapper";
import { AssistedViewProps } from "../AssistedView";
import { useCallback, useEffect, useState } from "react";
import "./ItemView.css";
import ImageDropdown from "../ImageDropdown/ImageDropdown";
import EquippedGear from "./EquippedGear";
import { RequestFileSelection } from "../../Resources/Resources";
import path from "path";
import { LogMessage, Severity } from "../../Logger";
import { resourceApi, sqlApi } from "../../NejlikaApi";

/*
Torso: ui/ingame/inventory_i80.dds
Left: ui/ingame/inventory_i84.dds
Right: ui/ingame/inventory_i88.dds
Back: ui/ingame/inventory_i90.dds
Special: ui/ingame/inventory_i94.dds
Rarity Full: ui/ingame/itemrollover_i8e.dds
Rarity Empty: ui/ingame/itemrollover_i8f.dds
*/

const rarityFull = "lego-universe:ui/ingame/itemrollover_i8e.dds";
const rarityEmpty = "lego-universe:ui/ingame/itemrollover_i8f.dds";
const armourIcon = "lego-universe:ui/ingame/itemrollover_i20.dds";
const imaginationIcon = "lego-universe:ui/ingame/itemrollover_i25.dds";
const healthIcon = "lego-universe:ui/ingame/itemrollover_i2a.dds";
const torsoIcon = "lego-universe:ui/ingame/inventory_i80.dds";
const leftIcon = "lego-universe:ui/ingame/inventory_i84.dds";
const rightIcon = "lego-universe:ui/ingame/inventory_i88.dds";
const backIcon = "lego-universe:ui/ingame/inventory_i90.dds";
const specialIcon = "lego-universe:ui/ingame/inventory_i94.dds";
const headIcon = "lego-universe:ui/ingame/inventory_i7c.dds";
const legsIcon = "lego-universe:ui/ingame/inventory_i8c.dds";

export const ItemView: React.FC<AssistedViewProps> = ({
    project, value, setValue, props, openFile, scope, readonly
}) => {
    const [objectInterface, setObjectInterface] = useState<TypeTemplateWrapper | null>(null);
    const [renderComponent, setRenderComponent] = useState<TypeTemplateWrapper | null>(null);
    const [itemComponent, setItemComponent] = useState<TypeTemplateWrapper | null>(null);
    const [showResourceSelector, setShowResourceSelector] = useState<boolean>(false);
    const [resourceSelectorType, setResourceSelectorType] = useState<'model' | 'icon'>('model');
    const [resourceSelectorValue, setResourceSelectorValue] = useState<string>('');
    const [healthBuff, setHealthBuff] = useState<TypeTemplateWrapper | null>(null);
    const [armourBuff, setArmourBuff] = useState<TypeTemplateWrapper | null>(null);
    const [imaginationBuff, setImaginationBuff] = useState<TypeTemplateWrapper | null>(null);
    const [healthSkill, setHealthSkill] = useState<TypeTemplateWrapper | null>(null);
    const [armourSkill, setArmourSkill] = useState<TypeTemplateWrapper | null>(null);
    const [imaginationSkill, setImaginationSkill] = useState<TypeTemplateWrapper | null>(null);
    const [health, setHealth] = useState<number>(0);
    const [armour, setArmour] = useState<number>(0);
    const [imagination, setImagination] = useState<number>(0);
    const [heatlhSkillIndex, setHealthSkillIndex] = useState<number>(-1);
    const [armourSkillIndex, setArmourSkillIndex] = useState<number>(-1);
    const [imaginationSkillIndex, setImaginationSkillIndex] = useState<number>(-1);
    const [skills, setSkills] = useState<{skill: TypeTemplateWrapper | string, type: 'Use' | 'Equip' | 'Consumable'}[]>([]);
    const [offsetGroups, setOffsetGroups] = useState<{[key: string]: number}>({});
    
    useEffect(() => {
        sqlApi.query(project, `SELECT GroupID, Description FROM AccessoryDefaultLoc`).then((data) => {
            const newGroups: {[key: string]: number} = {};

            for (const row of data) {
                newGroups[row["Description"] as string] = row["GroupID"] as number;
            }

            setOffsetGroups(newGroups);
        }).catch((error) => {
            LogMessage(error, Severity.ERROR);
        });
    }, [project]);

    useEffect(() => {
        TypeTemplateWrapper.fromObject(project, () => value, setValue).then(async (obj) => {
            setObjectInterface(obj);

            if (!readonly && obj.setDefault([], "values", "components")) {
                return;
            }

            const components = obj.getWithDefault([], "values", "components");
            const name = value["name"];

            let hasRenderComponent = false;
            let hasItemComponent = false;
            let hasSkillComponent = false;
            let skillComponentIndex = -1;
            
            for (let i = 0; i < components.length; i++) {
                const componentType = obj.getWithDefault(null, "values", "components", i, "type");
                const componentObj = await obj.getNestedObject("values", "components", i, "id");

                if (componentType === "RenderComponent") {
                    if (typeof componentObj === "object") {
                        setRenderComponent(componentObj);
                    }

                    hasRenderComponent = true;
                }
                else if (componentType === "ItemComponent") {
                    if (typeof componentObj === "object") {
                        setItemComponent(componentObj);
                    }

                    hasItemComponent = true
                }
                else if (componentType === "SkillComponent") {
                    hasSkillComponent = true;
                    skillComponentIndex = i;
                }
            }

            if (!readonly && !hasRenderComponent) {
                obj.addToArray({
                    "type": "RenderComponent",
                    "id": {
                        "type": "render-component",
                        "name": `${name}:render`,
                        "values": {

                        }
                    }
                }, "values", "components");
            }

            if (!readonly && !hasItemComponent) {
                obj.addToArray({
                    "type": "ItemComponent",
                    "id": {
                        "type": "item-component",
                        "name": `${name}:item`,
                        "values": {
                            "item-type": "special_r",
                            "base-value": 1000
                        }
                    }
                }, "values", "components");
            }

            const skills = obj.getWithDefault([], "values", "skills");

            if (!readonly && !hasSkillComponent && skills.length !== 0) {
                obj.addToArray({
                    "type": "SkillComponent",
                    "id": "lego-universe:0"
                }, "values", "components");
            }
            else if (!readonly && hasSkillComponent && skills.length === 0) {
                obj.removeFromArrayByIndex(skillComponentIndex, "values", "components");
            }

            const newSkills: ({skill: TypeTemplateWrapper | string, type: 'Use' | 'Equip' | 'Consumable'})[] = [];
    
            let hasHealth = false;
            let hasArmour = false;
            let hasImagination = false;
    
            for (let i = 0; i < skills.length; i++) {
                const skillObject = await obj.getNestedObject("values", "skills", i, "id");
    
                if (!skillObject) {
                    continue;
                }
    
                const skillType = obj.getWithDefault("Use", "values", "skills", i, "type");
    
                newSkills.push({ skill: skillObject, type: skillType });
    
                if (typeof skillObject === "string") {
                    continue;
                }
    
                if (skillType === 'Equip') {
                    const behavior = await skillObject.getNestedObject("values", "behavior");
    
                    console.log("Behavior", behavior);
    
                    if (!behavior || typeof behavior === "string") {
                        continue;
                    }
    
                    const behaviorType = behavior.getWithDefault(null, "values", "behavior-type");
    
                    if (behaviorType !== "Buff") {
                        continue;
                    }
    
                    const iHealth = behavior.getWithDefault(0, "values", "parameters", "life");
                    const iArmour = behavior.getWithDefault(0, "values", "parameters", "armor");
                    const iImagination = behavior.getWithDefault(0, "values", "parameters", "imag");
    
                    if (iHealth > 0) {
                        setHealthBuff(behavior);
                        setHealthSkill(skillObject);
                        setHealthSkillIndex(i);
    
                        setHealth(iHealth);
    
                        hasHealth = true;
                    }
    
                    if (iArmour > 0) {
                        setArmourBuff(behavior);
                        setArmourSkill(skillObject);
                        setArmourSkillIndex(i);
    
                        setArmour(iArmour);
    
                        hasArmour = true;
                    }
    
                    if (iImagination > 0) {
                        setImaginationBuff(behavior);
                        setImaginationSkill(skillObject);
                        setImaginationSkillIndex(i);
    
                        setImagination(iImagination);
    
                        hasImagination = true;
                    }
                }
            }
    
            setSkills(newSkills);
    
            if (!hasHealth)
            {
                setHealthBuff(null);
                setHealthSkill(null);
                setHealth(0);
            }
    
            if (!hasArmour)
            {
                setArmourBuff(null);
                setArmourSkill(null);
                setArmour(0);
            }
    
            if (!hasImagination)
            {
                setImaginationBuff(null);
                setImaginationSkill(null);
                setImagination(0);
            }
        });
    }, [value, project]);

    useEffect(() => {
        if (!objectInterface) {
            return;
        }

        let hasChanged = false;

        if (healthSkill === null && health > 0) {
            objectInterface.addToArray({
                "type": "Equip",
                "id": {
                    "type": "skill",
                    "name": `${value["name"]}:health-buff`,
                    "values": {
                        "behavior": {
                            "type": "behavior",
                            "name": `${value["name"]}:health-buff:behavior`,
                            "values": {
                                "behavior-type": "Buff",
                                "parameters": {
                                    "life": health
                                }
                            }
                        },
                        "life-bonus-ui": health,
                        "hide-icon": true
                    }
                }
            }, "values", "skills");

            hasChanged = true;
        }
        else if (healthSkill !== null && health === 0) {
            objectInterface.removeFromArrayByIndex(heatlhSkillIndex, "values", "skills");
            hasChanged = true;
        }
        
        if (armourSkill === null && armour > 0) {
            objectInterface.addToArray({
                "type": "Equip",
                "id": {
                    "type": "skill",
                    "name": `${value["name"]}:armour-buff`,
                    "values": {
                        "behavior": {
                            "type": "behavior",
                            "name": `${value["name"]}:armour-buff:behavior`,
                            "values": {
                                "behavior-type": "Buff",
                                "parameters": {
                                    "armor": armour
                                }
                            }
                        },
                        "armor-bonus-ui": armour,
                        "hide-icon": true
                    }
                }
            }, "values", "skills");

            hasChanged = true;
        }
        else if (armourSkill !== null && armour === 0) {
            objectInterface.removeFromArray(armourSkill.getObject(), "values", "skills");
            hasChanged = true;
        }

        if (imaginationSkill === null && imagination > 0) {
            objectInterface.addToArray({
                "type": "Equip",
                "id": {
                    "type": "skill",
                    "name": `${value["name"]}:imagination-buff`,
                    "values": {
                        "behavior": {
                            "type": "behavior",
                            "name": `${value["name"]}:imagination-buff:behavior`,
                            "values": {
                                "behavior-type": "Buff",
                                "parameters": {
                                    "imag": imagination
                                }
                            }
                        },
                        "imagination-bonus-ui": imagination,
                        "hide-icon": true
                    }
                }
            }, "values", "skills");

            hasChanged = true;
        }
        else if (imaginationSkill !== null && imagination === 0) {
            objectInterface.removeFromArray(imaginationSkill.getObject(), "values", "skills");
            hasChanged = true;
        }

        if (hasChanged) {
            return;
        }
        
        if (healthBuff && healthSkill && health > 0) {
            healthBuff.setObjectValue(health, "values", "parameters", "life");
            healthSkill.setObjectValue(health, "values", "life-bonus-ui");
        }

        if (armourBuff && armourSkill && armour > 0) {
            armourBuff.setObjectValue(armour, "values", "parameters", "armor");
            armourSkill.setObjectValue(armour, "values", "armor-bonus-ui");
        }

        if (imaginationBuff && imaginationSkill && imagination > 0) {
            imaginationBuff.setObjectValue(imagination, "values", "parameters", "imag");
            imaginationSkill.setObjectValue(imagination, "values", "imagination-bonus-ui");
        }
    }, [health, healthBuff, armour, armourBuff, imagination, imaginationBuff, skills, objectInterface]);

    const onSelectResource = useCallback((type: 'model' | 'icon') => {
        setShowResourceSelector(true);
        setResourceSelectorType(type);

        if (type === 'model') {
            RequestFileSelection((value) => {
                if (!value) {
                    return;
                }
                console.log("Selected model", value);
                renderComponent?.setObjectValue(value, "values", "render-asset");
            }, ["nif", "kfm"]);
        }
        else if (type === 'icon') {
            RequestFileSelection((value) => {
                if (!value) {
                    return;
                }
                console.log("Selected icon", value);
                renderComponent?.setObjectValue(value, "values", "icon-asset");
            }, ["dds"]);
        }
    }, [renderComponent]);

    if (!objectInterface) {
        return null;
    }

    if (value["type"] !== "object") {
        return (
            <div>
                <GenericError title="Item view can only be used with objects" />
            </div>
        )
    }

    if (!renderComponent || !itemComponent) {
        return null;
    }

    const [itemTypeProperty, itemType] = itemComponent.getProperty("values", "item-type");

    const rarity = itemComponent.getWithDefault(0, "values", "rarity");

    const setRarity = (value: number) => {
        console.log("Set rarity", value);
        itemComponent.setObjectValue(value, "values", "rarity");
    }

    const englishName = objectInterface.getWithDefault("", "locales", "name");
    const englishDescription = objectInterface.getWithDefault("", "locales", "description");

    const isBop = itemComponent.getWithDefault(false, "values", "is-bop");
    const isBoe = itemComponent.getWithDefault(false, "values", "is-boe");

    const bindValue = isBop ? "bop" : isBoe ? "boe" : "nobind";

    const onSetBinding = (value: string) => {
        console.log("Set binding", value);

        if (value === "bop") {
            itemComponent.setObjectValue(true, "values", "is-bop");
            itemComponent.setObjectValue(true, "values", "is-boe");
        }
        else if (value === "boe") {
            itemComponent.setObjectValue(false, "values", "is-bop");
            itemComponent.setObjectValue(true, "values", "is-boe");
        }
        else {
            itemComponent.setObjectValue(false, "values", "is-bop");
            itemComponent.setObjectValue(false, "values", "is-boe");
        }
    }

    const bindingOptions = [
        { value: "bop", text: "Bind on Pickup", image: resourceApi.imageUrl(project, "lego-universe:ui/ingame/itemrollover_i75.dds") },
        { value: "boe", text: "Bind on Equip", image: resourceApi.imageUrl(project, "lego-universe:ui/ingame/itemrollover_i68.dds") },
        { value: "nobind", text: "No Binding", image: resourceApi.imageUrl(project, "lego-universe:ui/ingame/itemrollover_i78.dds") },
    ]

    const icon = resourceApi.imageUrl(project, renderComponent.getWithDefault("", "values", "icon-asset"));
    const equipLocation = itemComponent.getWithDefault(null, "values", "equip-location");
    const offsetGroupID = itemComponent.getWithDefault(1, "values", "offset-group-id");

    const setEquipLocation = (newValue: string) => {
        console.log("Set equip location", newValue);
        if (newValue === "consumable") {
            itemComponent.setObjectValue("consumable", "values", "item-type");
            itemComponent.setObjectValue(null, "values", "equip-location");
            return;
        }

        if (newValue === equipLocation) {
            itemComponent.setObjectValue(null, "values", "equip-location");
            return;
        }

        itemComponent.setObjectValue(newValue, "values", "equip-location");

        switch (newValue) {
            case "hair":
                itemComponent.setObjectValue("hat", "values", "item-type");
                break;
            case "special_r":
                itemComponent.setObjectValue("right-hand", "values", "item-type");
                break;
            case "legs":
                itemComponent.setObjectValue("legs", "values", "item-type");
                break;
            case "clavicle":
                itemComponent.setObjectValue("neck", "values", "item-type");
                break;
            case "chest":
                itemComponent.setObjectValue("chest", "values", "item-type");
                break;
            case "special_l":
                itemComponent.setObjectValue("left-hand", "values", "item-type");
                break;
        }
    }

    return (
        <>
        <Row className="p-1">
            <Col md={12} sm={12} xl={7} xxl={7} className="inventory-view p-1">
                <Row>
                    <Col md={9}>
                        <div className="inventory-item-view">
                            <div>
                                <div className="inventory-item-name">
                                    <input className="inventory-text-input text-center m-0 w-100"
                                        value={englishName}
                                        onChange={(e) => objectInterface.setObjectValue(e.target.value, "locales", "name")}
                                        placeholder="Name"
                                    />
                                </div>
                                <div>
                                    <div className="row align-items-center">
                                        <div className="col-auto ms-auto me-auto">
                                            <span>
                                                <img src={resourceApi.imageUrl(project, healthIcon)} alt="Health" width={32} height={32} />
                                                <span className="white-text ms-3">+</span>
                                                <input className="inventory-text-input"
                                                    type="number"
                                                    value={health}
                                                    onChange={(e) => setHealth(Number(e.target.value))}
                                                    size={3}
                                                />
                                            </span>
                                        </div>
                                        <div className="col-auto ms-auto me-auto">
                                            <span>
                                                <img src={resourceApi.imageUrl(project, armourIcon)} alt="Armour" width={32} height={32} />
                                                <span className="white-text ms-3">+</span>
                                                <input className="inventory-text-input"
                                                    type="number"
                                                    value={armour}
                                                    onChange={(e) => setArmour(Number(e.target.value))}
                                                    size={3}
                                                />
                                            </span>
                                        </div>
                                        <div className="col-auto ms-auto me-auto">
                                            <span>
                                                <img src={resourceApi.imageUrl(project, imaginationIcon)} alt="Imagination" width={32} height={32} />
                                                <span className="white-text ms-3">+</span>
                                                <input className="inventory-text-input"
                                                    type="number"
                                                    value={imagination}
                                                    onChange={(e) => setImagination(Number(e.target.value))}
                                                    size={3}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="inventory-item-description">
                                    <input className="inventory-text-input text-center m-0 w-100"
                                        value={englishDescription}
                                        onChange={(e) => objectInterface.setObjectValue(e.target.value, "locales", "description")}
                                        placeholder="Description"
                                    />
                                </div>
                            </div>
                            <div className="inventory-item-view-divider"/>
                            <div className="row align-items-end rarity-row">
                                <div className="col-3 text-container">
                                    <span className="white-text">Rarity:</span>
                                </div>
                                <div className="col-6 diagonal-images">
                                    <div className="inventory-rarity-container inventory-rarity-justify" onClick={() => setRarity(1)}>
                                        <img src={resourceApi.imageUrl(project, (rarity == 1) ? rarityFull : rarityEmpty)} alt="rarity"
                                            className="inventory-rarity-img-1"
                                        />
                                    </div>
                                    <div className="inventory-rarity-container" onClick={() => setRarity(2)}>
                                        <img src={resourceApi.imageUrl(project, (rarity == 2) ? rarityFull : rarityEmpty)} alt="rarity"
                                            className="inventory-rarity-img-2"
                                        />
                                    </div>
                                    <div className="inventory-rarity-container" onClick={() => setRarity(3)}>
                                        <img src={resourceApi.imageUrl(project, (rarity == 3) ? rarityFull : rarityEmpty)} alt="rarity"
                                            className="inventory-rarity-img-3"
                                        />
                                    </div>
                                    <div className="inventory-rarity-container" onClick={() => setRarity(4)}>
                                        <img src={resourceApi.imageUrl(project, (rarity == 4) ? rarityFull : rarityEmpty)} alt="rarity"
                                            className="inventory-rarity-img-4"
                                        />
                                    </div>
                                    <div className="col-3">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="inventory-item-view-divider"/>
                            <div className="row">
                                <div className="col-auto me-3 inventory-coin-span">
                                    <img src={resourceApi.imageUrl(project, "lego-universe:ui/ingame/statusbar_i7.dds")} alt="coin"/>
                                    <span>
                                        <input className="inventory-text-input"
                                            type="number"
                                            value={itemComponent.getObjectValue("values", "base-value") / 10}
                                            onChange={(e) => itemComponent.setObjectValue(Number(e.target.value) * 10, "values", "base-value")} />
                                    </span>
                                </div>
                                <div className="col-auto ms-auto">
                                    <ImageDropdown
                                        items={bindingOptions}
                                        selectedValue={bindValue}
                                        onSelect={onSetBinding}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='inventory-item' onClick={() => onSelectResource('icon')}>
                            <img className="inventory-icon"
                                src={icon}
                                alt={renderComponent.getWithDefault("", "values", "icon-asset")}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={12} sm={12} xl={5} xxl={5} className="equipped-view p-1">
                <EquippedGear
                    project={project}
                    model={renderComponent.getObjectValue("values", "render-asset")}
                    leftButtons={[
                        {url: equipLocation == "hair" ? icon : resourceApi.imageUrl(project, headIcon), onClick: () => setEquipLocation("hair")},
                        {url: equipLocation == "special_r" ? icon : resourceApi.imageUrl(project, rightIcon), onClick: () => setEquipLocation("special_r")},
                        {url: equipLocation == "legs" ? icon : resourceApi.imageUrl(project, legsIcon), onClick: () => setEquipLocation("legs")},
                        {url: itemType == "consumable" ? icon :resourceApi.imageUrl(project, specialIcon), onClick: () => setEquipLocation("consumable")}
                    ]}
                    rightButtons={[
                        {url: equipLocation == "clavicle" ? icon : resourceApi.imageUrl(project, backIcon), onClick: () => setEquipLocation("clavicle")},
                        {url: equipLocation == "chest" ? icon : resourceApi.imageUrl(project, torsoIcon), onClick: () => setEquipLocation("chest")},
                        {url: equipLocation == "special_l" ? icon : resourceApi.imageUrl(project, leftIcon), onClick: () => setEquipLocation("special_l")},
                    ]}
                    attachmentPoint={equipLocation}
                    offsetGroupID={offsetGroupID}
                />
            </Col>
        </Row>
        <Row>
            <Col md={12} sm={12} xl={12} xxl={12}>
                <GenericInput
                    title="In-World Model"
                    value={renderComponent.getObjectValue("values", "render-asset")}
                    setValue={(value) => renderComponent.setObjectValue(value, "values", "render-asset")}
                    readonly={readonly}
                    type="text"
                />
                <GenericEnum
                    title="Item Type"
                    value={itemType}
                    setValue={(value) => itemComponent.setObjectValue(value, "values", "item-type")}
                    options={Object.keys(itemTypeProperty.enum ?? {})}
                    formatOption={(option) => FormatEnumOption(option)}
                    readonly={readonly}
                />
                <GenericEnum
                    title="Equip Offset Group"
                    value={offsetGroupID}
                    setValue={(value) => itemComponent.setObjectValue(Number(value), "values", "offset-group-id")}
                    options={Object.keys(offsetGroups)}
                    formatOption={(option) => `${option}`}
                    readonly={readonly}
                    optionValues={offsetGroups}
                />
            </Col>
        </Row>
        </>
    );
};