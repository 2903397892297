import React, { useCallback } from 'react';

import {

} from 'react-bootstrap';

import './components.css';
import ReactCodeMirror from '@uiw/react-codemirror';
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { json } from "@codemirror/lang-json"
import { basicSetup } from "codemirror"
import { useEffect } from 'react';
import { useState } from 'react';
import { acceptCompletion, autocompletion, CompletionContext, startCompletion } from '@codemirror/autocomplete';
import { indentUnit } from '@codemirror/language';
import { keymap } from "@codemirror/view"
import { Completions } from './Completions';
import { BehaviorGui } from './BehaviorGui';

import { ErrorScreen } from './ErrorScreen/ErrorScreen';
import { GraphicalEditor } from './graphical/GraphicalEditor';
import { OpenFile } from './OpenFile';
import { authorization, Permission } from '../Authorization';
import { EditorMode } from './EditorMode';
import { AssistedView } from './AssistedView/AssistedView';
import { GenericText } from './GenericText';
import { typeApi } from './NejlikaApi';

let currentSavingId = 0;

const language = json();

interface ModEditorProps {
    project: string;
    title: string;
    value: any;
    setValue: (value: any) => void;
    openFile: OpenFile;
    openEditors: OpenFile[];
    setOpenEditors: (openEditors: OpenFile[]) => void;
    guiEnabled: EditorMode;
    setGuiEnabled: (guiEnabled: EditorMode) => void;
}

export const ModEditor: React.FC<ModEditorProps> = ({ project, title, value, setValue, openFile, openEditors, setOpenEditors, guiEnabled, setGuiEnabled }) => {
    const [behaviorGui, setBehaviorGui] = useState<JSX.Element | null>(null);
    const [textEditorValue, setTextEditorValue] = useState<string>(JSON.stringify(value, null, 4));
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        if (guiEnabled == 'source') {
            setGuiEnabled('graphical');
        }
        setTextEditorValue(JSON.stringify(value, null, 4));
    }, [openFile]);
    
    /*const textEditorValueRef = React.useRef(textEditorValue);
    const setTextEditorValue = (data: string) => {
        textEditorValueRef.current = data;
        _setTextEditorValue(data);
    }*/

    const validateValue = useCallback((obj: any) => {
        typeApi.validate(project, obj).then(data => {
            setErrorMessage(data);
        });
    }, [project]);

    useEffect(() => {
        if (guiEnabled == 'assisted') {
            setBehaviorGui(<BehaviorGui
                value={value}
                setValue={(value: any, name: string | null) => {
                    setValue(value);
                }
                }
                title={title}
                name={null}
                parent={null}
            />);
        }
        else if (guiEnabled == 'source') {
            setTextEditorValue(JSON.stringify(value, null, 4));
            setBehaviorGui(null);

            // Close all editors which have the same uniqueId and a scope that is a subset of the current scope
            const currentScope = openFile.scope;
            const invalidEditors = openEditors.filter(editor => {
                if (editor.uniqueId === openFile.uniqueId) {
                    if (editor.scope.length > currentScope.length) {
                        let valid = true;
                        for (let i = 0; i < currentScope.length; i++) {
                            if (editor.scope[i] !== currentScope[i]) {
                                valid = false;
                                break;
                            }
                        }
                        return valid;
                    }
                }
                return false;
            });
            
            setOpenEditors(openEditors.filter(editor => !invalidEditors.includes(editor)));
        }
    }, [guiEnabled]);

    // Simple editor which takes a value and a setValue function
    const completions = new Completions(value);
    
    const myCompletions = (context: CompletionContext) => {
        return completions.myCompletions(context);
    }

    let canWriteMods = authorization.hasPermission(Permission.WriteMods);

    const modName: undefined | null | string = value["name"];

    if (modName && modName.startsWith("wonderland:")) {
        canWriteMods = false;
    }

    return (
        <div id='editor-container'>
            {/* Visiable if guiEnabled is false */}
            {guiEnabled == 'source' && (
            <div className='editor-main' style={{ display: (guiEnabled == 'source') ? 'block' : 'none' }}>
                <div className='scrollable-h-100'>
                    <ReactCodeMirror id='editor'
                        height='100%'
                        maxHeight='100%'
                        theme={vscodeDark}
                        readOnly={!canWriteMods}
                        extensions={[
                            basicSetup,
                            language,
                            autocompletion({ override: [myCompletions] }),
                            indentUnit.of("    "),
                            keymap.of([
                                { key: "Enter", run: acceptCompletion },
                                { key: "Shift-Space", run: startCompletion }
                            ])
                        ]}
                        value={textEditorValue}
                        onChange={(value) => {
                            setTextEditorValue(value);
                            setValue(JSON.parse(value));
                            validateValue(JSON.parse(value));
                        }}
                    />
                </div>
            </div>)}
            {/* Visiable if guiEnabled is true */}
            {guiEnabled == 'graphical' && (
            <div className='editor-main' style={{ display: (guiEnabled == 'graphical') ? 'block' : 'none' }}>
                <div className='scrollable-h-100'>
                    <GraphicalEditor
                        project={project}
                        value={value}
                        props={{
                            openEditors: openEditors,
                            setOpenEditors: setOpenEditors
                        }}
                        setValue={(value: any) => {
                            console.log("Setting value [ModEditor|GraphicalEditor]: " + value);
                            const text = JSON.stringify(value, null, 4);
                            setTextEditorValue(text);
                            setValue(value);
                            validateValue(value);
                        }
                        }
                        openFile={openFile}
                        scope={[]}
                        readonly={!canWriteMods}
                    />
                </div>
            </div>)}
            {/* Visiable if guiEnabled is true */}
            {guiEnabled == 'assisted' && (
            <div className='editor-main' style={{ display: (guiEnabled == 'assisted') ? 'block' : 'none' }}>
                <div className='scrollable-h-100 p-3'>
                    {value["type"] === "skill" && <div style={{ height: '100%', padding: '10px' }}>
                        {behaviorGui}
                    </div>}
                    {value["type"] !== "skill" && <div style={{ height: '100%', padding: '10px' }}>
                        <AssistedView
                            project={project}
                            value={value}
                            props={{
                                openEditors: openEditors,
                                setOpenEditors: setOpenEditors
                            }}
                            setValue={(value: any) => {
                                console.log("Setting value [ModEditor|GraphicalEditor]: " + value);
                                const text = JSON.stringify(value, null, 4);
                                setTextEditorValue(text);
                                setValue(value);
                                validateValue(value);
                            }
                            }
                            openFile={openFile}
                            scope={[]}
                            readonly={!canWriteMods}
                        />
                    </div>}
                </div>
            </div>)}
            {errorMessage !== "" && <ErrorScreen title="Error" message={errorMessage} />}
        </div>
    );
};

interface EditorProps {
    project: string;
    openEditors: OpenFile[];
    setOpenEditors: (openEditors: OpenFile[]) => void;
    guiEnabled: EditorMode;
    setGuiEnabled: (guiEnabled: EditorMode) => void;
}

export const Editor: React.FC<EditorProps> = ({ project, openEditors, setOpenEditors, guiEnabled, setGuiEnabled }) => {
    // Horizontal strollable selection of open files
    const [selectedFile, setSelectedFile] = useState<number>(0);
    const [workingFile, setWorkingFile] = useState<OpenFile | null>(null);
    const [workingValue, setWorkingValue] = useState<any | null>(null);
    const [lastEditorSize, setLastEditorSize] = useState<number>(0);
    //const [editors, setEditors] = useState<JSX.Element[]>([]);

    useEffect(() => {
        /*setEditors(openEditors.map((editor, index) => {
            {
                return <ModEditor key={index} title={editor.title} value={editor.value} setValue={editor.setValue} 
                    openEditors={openEditors} setOpenEditors={setOpenEditors} />;
            }
        }));*/

        if (openEditors.length === 0) {
            setWorkingFile(null);
            setWorkingValue(null);
            return;
        }

        if (openEditors.length > lastEditorSize) {
            setSelectedFile(Math.min(Math.max(openEditors.length - 1, 0), openEditors.length - 1));
        }
        else if (openEditors.length < lastEditorSize) {
            // Search for the current file
            let index = 0;
            for (let i = 0; i < openEditors.length; i++) {
                if (openEditors[i].title === workingFile?.title) {
                    index = i;
                    break;
                }
            }

            setSelectedFile(index);
        }

        setLastEditorSize(openEditors.length);
        
        if (openEditors.length > 0 && selectedFile < openEditors.length) {
            setWorkingFile(openEditors[selectedFile]);
            setWorkingValue(openEditors[selectedFile].value);
        }
    }, [openEditors]);

    useEffect(() => {
        if (openEditors.length > 0) {
            setWorkingFile(openEditors[selectedFile]);
            setWorkingValue(openEditors[selectedFile].value);
        }
    }, [selectedFile]);

    const closeFile = (file: OpenFile) => {
        const newOpenEditors = openEditors.filter(editor => editor.title !== file.title);
        setOpenEditors(newOpenEditors);

        // Select the file to the left
        if (selectedFile > 0) {
            setSelectedFile(selectedFile - 1);
        }
        else if (selectedFile === 0 && newOpenEditors.length > 0) {
            setSelectedFile(0);
        }
    };

    let canWriteMods = authorization.hasPermission(Permission.WriteMods);

    return (
        <div id='editor-container'>
            <div className='editor-header'>
                {openEditors.map((editor, index) => {
                    return (
                        <>
                        <button key={index} className="file-selector-button vs-bg" onClick={() => {
                            setSelectedFile(index);
                        }}>
                            <span className={index === selectedFile ? 'primary' : 'secondary'}>
                                {editor.title}{(!canWriteMods || editor.title.startsWith("wonderland:")) &&
                                    <span className='log-warning'> (read-only)</span>
                                }
                            </span>
                            <button className='file-close-button vs-bg' onClick={() => {
                                closeFile(editor);
                            }}>x</button>
                        </button>
                        </>
                    );
                })}
            </div>
            {/* display: none for those that are not selected */}
            {/*editors.map((editor, index) => {
                return (
                    <div key={index} style={{ display: index === selectedFile ? 'block' : 'none' }}>
                        {editor}
                    </div>
                );
            })*/}
            <div className='editor-main-top' style={{ display: 'block' }}>
                {workingFile &&
                <ModEditor
                    project={project}
                    title={workingValue['name'] || workingFile.title}
                    value={workingValue}
                    setValue={(value: any) => {
                        const scope = workingFile.scope;

                        console.log("Setting value [Editor]: ", value);
                        console.log("Scope: ", scope);

                        let obj = openEditors.find(editor => (editor.uniqueId === workingFile.uniqueId) && editor.isFile)?.value;

                        console.log("Working from: ", obj);
                        if (scope.length > 0) {
                            let parent = obj;

                            for (let i = 0; i < scope.length - 1; i++) {
                                parent = parent[scope[i]];
                            }

                            parent[scope[scope.length - 1]] = value;
                        }
                        else {
                            obj = value;
                        }
                        
                        workingFile.setValue(obj);

                        workingFile.value = value;

                        setWorkingValue(value);
                    }}
                    openEditors={openEditors}
                    setOpenEditors={setOpenEditors}
                    openFile={workingFile}
                    guiEnabled={guiEnabled}
                    setGuiEnabled={setGuiEnabled}
                />
                }
            </div>
        </div>
    );
}
