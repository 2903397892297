import React, { useEffect, useState } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { GenericInput } from './GenericInput';
import { GenericBtn } from './GenericBtn';
import { GuideHandlers } from './Guide/GuideHandler';

interface CreateModPackCardProps {
    value: any;
    save: (value: {name: string, description: string, author: string, version: string}) => void;
    onClose: () => void;
    isEditing?: boolean;
}

export const CreateModPackCard: React.FC<CreateModPackCardProps> = ({ value, save, onClose, isEditing }) => {
    const [show, setShow] = useState<boolean>(true);
    const [name, setName] = useState<string>(value?.name || "");
    const [description, setDescription] = useState<string>(value?.description || "");
    const [author, setAuthor] = useState<string>(value?.author || "");
    const [version, setVersion] = useState<string>(value?.version || "");
    const [valid, setValid] = useState<boolean>(false);

    useEffect(() => {
        if (!name) {
            setValid(false);
            return;
        }

        // Make sure it's only lowercase letters and dashes
        const valid = /^[a-z0-9-]+$/.test(name);

        setValid(valid);
    }, [name]);

    return (
        <Modal show={show} onHide={() => {
            setShow(false);
            onClose();
        }}>
            <Card className='vs-bg p-2'>
                <Row className='center-text'>
                    <p className='wheat-text'>{isEditing ? 'Edit' : 'Create'} Mod Pack</p>
                </Row>
                <div id="create-mod-name">
                    <GenericInput title='Name' type='text' value={name} setValue={setName} readonly={isEditing} />
                </div>
                <GenericInput title='Description' type='textarea' value={description} setValue={setDescription} />
                <GenericInput title='Author' type='text' value={author} setValue={setAuthor} />
                <GenericInput title='Version' type='text' value={version} setValue={setVersion} />
                <Row className='center-text'>
                    <Col md={12}>
                        <GenericBtn 
                            id='create-mod-save'
                            primary={true}
                            readonly={!valid}
                            title={isEditing ? 'Save' : 'Create'}
                            onClick={() => {
                            save({name, description, author, version});
                        }} />
                    </Col>
                </Row>
                {!valid && <Row className='center-text p-2'>
                    <Col md={12}>
                        <p className='log-error'>Invalid name, only lowercase letters, numbers, and dashes allowed</p>
                    </Col>
                </Row>}
                <GuideHandlers elements={['create-mod-name', 'create-mod-save']} />
            </Card>
        </Modal>
    );
};