import { ModelRenderer } from "./ModelRenderer";
import "./Graphics.css";
import { WorldScene as WorldScene, World, SceneObject } from "../World";

import React, { useEffect, useRef, useState, useCallback } from "react";
import {
    Engine,
    Scene,
    SceneLoader,
    ArcRotateCamera,
    HemisphericLight,
    Vector3,
    AnimationGroup,
    Color4,
    Quaternion,
    TransformNode,
    BoundingInfo,
    ActionManager,
    ExecuteCodeAction,
    AbstractMesh,
    UniversalCamera,
    Mesh,
    DirectionalLight,
    Color3,
    Matrix,
    FreeCameraMouseInput,
    InstancedMesh,
    AssetContainer,
    MeshBuilder,
    Texture,
    ShaderMaterial
} from "@babylonjs/core";
import "@babylonjs/loaders"; // Ensures GLTF loader is included
import { Terrain, TerrainChunk } from "./Terrain";
import { TerrainLoader } from "./TerrainLoader";
import { resourceApi } from "../NejlikaApi";

interface WorldViewerProps {
    project: string;
    zone: string;
}

interface WorldModel {
    rootNode: TransformNode;
}

const loadedMeshes = new Map<string, AssetContainer>();
const loadingPromises = new Map<string, Promise<AssetContainer>>();

interface CullingEntry {
    cullDistanceSquared: number;
    abstractMeshes: AbstractMesh[];
}

const cullingEntries = new Array<CullingEntry>();
let previousCameraPosition: Vector3 = new Vector3(0, 0, 0);
let cameraMovementThreshold = 5;

export const WorldViewer: React.FC<WorldViewerProps> = ({ project, zone }) => {
    const [world, setWorld] = useState<World | null>(null);
    const [terrain, setTerrain] = useState<Terrain | null>(null);

    useEffect(() => {
        resourceApi.getWorld(project, zone).then(setWorld);
    }, [zone, project]);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [engine, setEngine] = useState<Engine | null>(null);
    const [scene, setScene] = useState<Scene | null>(null);
    const [camera, setCamera] = useState<UniversalCamera | null>(null);
    const animationGroupsRef = useRef<AnimationGroup[]>([]);
    const [models, setModels] = useState<WorldModel[]>([]);
    const [skybox, setSkybox] = useState<AbstractMesh[] | null>(null);
    const [hemisphericLight, setHemisphericLight] = useState<HemisphericLight | null>(null);
    const [directionalLight, setDirectionalLight] = useState<DirectionalLight | null>(null);
    const [flipHorizontal, setFlipHorizontal] = useState(false);

    useEffect(() => {
        if (canvasRef.current) {
            const newEngine = new Engine(canvasRef.current, true);
            const newScene = new Scene(newEngine);
            setEngine(newEngine);
            setScene(newScene);

            // Setup camera and lights
            const camera = new UniversalCamera(
                "camera",
                new Vector3(0, 0, 0),
                newScene
            );
            camera.attachControl(canvasRef.current, true);

            newScene.useRightHandedSystem = true;

            // Move with WASD and arrow keys
            camera.keysUp = [87]; // W
            camera.keysDown = [83]; // S
            if (flipHorizontal) {
                camera.keysRight = [65]; // A
                camera.keysLeft = [68]; // D
            } else {
                camera.keysRight = [68]; // D
                camera.keysLeft = [65]; // A
            }
            camera.speed = 2;
            camera.inertia = 0.9;

            // Make the horizonal camera panning movement flip
            // Flip horizontal mouse movement
            if (flipHorizontal) {
                camera.angularSensibility *= -1;

                // Modify the projection matrix to flip horizontally
                const originalProjectionMatrix = camera.getProjectionMatrix();
                camera.getProjectionMatrix = function () {
                    if (flipHorizontal) {
                        let flipMatrix = originalProjectionMatrix;
                        // Flip horizontally
                        flipMatrix = flipMatrix.multiply(Matrix.Scaling(-1, 1, 1));
                        return flipMatrix;
                    }
                    return originalProjectionMatrix;
                };
            }

            setCamera(camera);
            
            
            const light = new HemisphericLight("light1", new Vector3(0, 1, 0), newScene);
            light.intensity = 1.5;

            setHemisphericLight(light);

            const directionalLight = new DirectionalLight("dir01", new Vector3(0, -1, 0), newScene);
            directionalLight.position = new Vector3(0, 50, 0);
            directionalLight.intensity = 1;

            setDirectionalLight(directionalLight);

            // Set background to transparent
            newScene.clearColor = new Color4(0, 0, 0, 0);

            // Render loop
            newEngine.runRenderLoop(() => {
                newScene.render();

                // Apply culling distances
                if (camera) {
                    const cameraPosition = camera.position;
                    const cameraMoved = Vector3.DistanceSquared(previousCameraPosition, cameraPosition) > cameraMovementThreshold;
                    previousCameraPosition = cameraPosition;

                    if (cameraMoved) {
                        cullingEntries.forEach((entry) => {
                            const distance = Vector3.DistanceSquared(camera.position, entry.abstractMeshes[0].position);
                            const isVisible = distance < entry.cullDistanceSquared;

                            entry.abstractMeshes.forEach((mesh) => {
                                mesh.isVisible = isVisible;
                            });
                        });
                    }
                }
            });

            const resize = () => {
                newEngine.resize();
            }

            // Resize the engine on window resize
            if (window) {
                window.addEventListener("resize", resize);
            }

            // Capture the scroll wheel event to prevent page scrolling
            const preventScroll = (event: WheelEvent) => {
                event.preventDefault();
            }

            if (canvasRef.current) {
                canvasRef.current.addEventListener("wheel", preventScroll);
            }

            // Cleanup
            return () => {
                newScene.dispose();
                newEngine.dispose();

                if (window) {
                    window.removeEventListener("resize", resize);
                }

                if (canvasRef.current) {
                    canvasRef.current.removeEventListener("wheel", preventScroll);
                }
            };
        }
    }, []);

    // Make the skybox follow the camera
    useEffect(() => {
        // Make it update every frame
        if (skybox) {
            const onBeforeRender = () => {
                if (camera) {
                    skybox.forEach((mesh) => {
                        mesh.position = camera.position;
                    });
                }
            };

            if (scene) {
                scene.registerBeforeRender(onBeforeRender);
            }

            return () => {
                if (scene) {
                    scene.unregisterBeforeRender(onBeforeRender);
                }
            };
        }
    }, [camera, skybox]);
    
    const getVisibility = useCallback((object: SceneObject) => {
        let isHidden = false;
        if (object) {
            if (object.x['renderDisabled']?.v === "1") isHidden = true;
            if (object.x['carver_only']?.v === "1") isHidden = true;
            if (object.x['trigger_id']) isHidden = true;
            if (object.l === 30021) isHidden = true;
            if (object.x['spawntemplate']?.v === "30021") isHidden = true;
        }
        return !isHidden;
    }, []);
    
    const applyObjectTransformations = useCallback((target: TransformNode, object: SceneObject) => {
        const position = new Vector3(object.p.x, object.p.y, object.p.z);
        const rotation = new Quaternion(object.r.x, object.r.y, object.r.z, object.r.w);
        const scaling = new Vector3(object.s, object.s, object.s);
    
        target.position = position;
        target.rotationQuaternion = rotation;
        target.scaling = scaling;
    }, []);
    
    const applyObjectSettings = useCallback((targets: TransformNode[], object: SceneObject, worldScene: WorldScene) => {
        const visibility = getVisibility(object);
        targets.forEach((node) => {
            if (!node.parent) {
                applyObjectTransformations(node, object);
            }
            
            // Find all meshes in the hierarchy
            const meshes = node.getChildMeshes();

            // Apply visibility settings
            meshes.forEach((mesh) => {
                mesh.isVisible = visibility;
            });

            if (object.x['renderCullingGroup']) {
                if (!worldScene.environment["culling-distances"]) {
                    return;
                }

                const cullingGroup = worldScene.environment["culling-distances"][
                    object.x['renderCullingGroup'].v
                ];

                if (cullingGroup.min === 0 && cullingGroup.max === 0) {
                    return;
                }
                
                const cullingDistance = Math.max(cullingGroup.min, cullingGroup.max);

                // Add the mesh to the culling group
                const entry: CullingEntry = {
                    cullDistanceSquared: cullingDistance * cullingDistance,
                    abstractMeshes: meshes,
                }
                
                cullingEntries.push(entry);
            }
        });
    }, [applyObjectTransformations, getVisibility, world]);

    const instantiateModelsToScene = useCallback((assetContainer: AssetContainer, object: SceneObject, worldScene: WorldScene) => {
        const instance = assetContainer.instantiateModelsToScene();
        const rootNodes = instance.rootNodes.map((node) => node as TransformNode);

        // Normalize root node transforms
        rootNodes.forEach((node) => {
            node.position.set(0, 0, 0);
            node.rotationQuaternion = Quaternion.Identity();
            node.scaling.set(1, 1, 1);
        });

        // Apply transformations and visibility settings
        applyObjectSettings(rootNodes, object, worldScene);

        // Ensure animations play
        instance.animationGroups.forEach((group) => {
            group.play(true); // Loop animations
        });

        return instance;
    }, [applyObjectSettings]);
    
    const loadModel = useCallback((modelUrl: string, object: SceneObject, worldScene: WorldScene) => {
        if (!scene) return;

        // Check if the model is already loaded
        if (loadedMeshes.has(modelUrl)) {
            const cachedMeshes = loadedMeshes.get(modelUrl)!;
            instantiateModelsToScene(cachedMeshes, object, worldScene);
            return;
        }

        // Check if the model is already being loaded
        if (loadingPromises.has(modelUrl)) {
            loadingPromises.get(modelUrl)!.then((result) => {
                instantiateModelsToScene(result, object, worldScene);
            });
            return;
        }

        // Start loading the model and track the promise
        const loadPromise = SceneLoader.LoadAssetContainerAsync(modelUrl, "", scene).then((result) => {
            // Filter to only include Mesh instances
            const meshes = result.meshes.filter(
                (mesh): mesh is Mesh => mesh instanceof Mesh
            );

            // Disable culling for all meshes
            meshes.forEach((mesh) => {
                mesh.alwaysSelectAsActiveMesh = true;
            });

            // Cache the meshes
            loadedMeshes.set(modelUrl, result);

            return result;
        }).catch((error) => {
            console.error("Failed to load model:", error);
            throw error; // Ensure the promise chain handles the error
        }).finally(() => {
            // Remove from loadingPromises regardless of success or failure
            loadingPromises.delete(modelUrl);
        });

        loadingPromises.set(modelUrl, loadPromise);

        // Chain the promise to instantiate the models
        loadPromise.then((result) => {
            instantiateModelsToScene(result, object, worldScene);
        });
    }, [scene, applyObjectSettings]);

    const loadSkybox = useCallback((worldScene: WorldScene) => {
        if (hemisphericLight && directionalLight) {
            const environment = worldScene.environment;
            const directionalColor = environment["directional-light-color"];
            const ambientColor = environment.ambient;
            const specularColor = environment.specular;
            const upperHemiColor = environment["upper-hemi"];

            directionalLight.diffuse = new Color3(directionalColor.r, directionalColor.g, directionalColor.b);
            hemisphericLight.diffuse = new Color3(ambientColor.r, ambientColor.g, ambientColor.b);
            hemisphericLight.specular = new Color3(specularColor.r, specularColor.g, specularColor.b);
            hemisphericLight.groundColor = new Color3(upperHemiColor.r, upperHemiColor.g, upperHemiColor.b);
        }

        if (scene && worldScene.environment.skybox) {
            resourceApi.getGeometryUrl(project, worldScene.environment.skybox).then((skyboxUrl) => {
                SceneLoader.ImportMeshAsync("", "", skyboxUrl, scene).then((result) => {
                    let skybox: AbstractMesh[] = [];
                    const { meshes } = result;
                    meshes.forEach((mesh) => {
                        mesh.alwaysSelectAsActiveMesh = true;
                        if (!mesh.parent) {
                            skybox.push(mesh);
                            mesh.scaling = new Vector3(2000, 2000, 2000);
                        }
                    });

                    setSkybox(skybox);
                }).catch((error) => {
                    console.error("Failed to load skybox:", error);
                });
            });
        }
    }, [scene, hemisphericLight, directionalLight, project]);

    const focusCameraOnBounds = useCallback(() => {
        if (scene && camera) {
            let minX = Number.MAX_VALUE;
            let minY = Number.MAX_VALUE;
            let minZ = Number.MAX_VALUE;
            let maxX = Number.MIN_VALUE;
            let maxY = Number.MIN_VALUE;
            let maxZ = Number.MIN_VALUE;

            for (let model of models) {
                const boundingInfo = model.rootNode.getHierarchyBoundingVectors();
                const min = boundingInfo.min;
                const max = boundingInfo.max;

                minX = Math.min(minX, min.x);
                minY = Math.min(minY, min.y);
                minZ = Math.min(minZ, min.z);
                maxX = Math.max(maxX, max.x);
                maxY = Math.max(maxY, max.y);
                maxZ = Math.max(maxZ, max.z);
            }

            const boundingBox = new BoundingInfo(new Vector3(minX, minY, minZ), new Vector3(maxX, maxY, maxZ));
            const center = boundingBox.boundingSphere.centerWorld;

            camera.target = center;
        }
    }, [scene, camera]);

    useEffect(() => {
        if (world && scene) {
            // Clear the scene
            if (scene.meshes) {
                scene.meshes.forEach((mesh) => mesh.dispose());
            }

            let first = true;

            let modelPairs: Promise<[string, SceneObject, WorldScene]>[] = [];

            for (let worldSceneId of Object.keys(world.scenes)) {
                const worldScene = world.scenes[worldSceneId];
                
                // Load skybox
                if (first && worldScene.environment.skybox) {
                    first = false;
                    loadSkybox(worldScene);
                }

                for (let objectName of Object.keys(worldScene.objects)) {
                    const object = worldScene.objects[objectName];
                    let lot = object.l.toString();

                    if (object.x['spawntemplate'])
                    {
                        lot = object.x['spawntemplate'].v;
                    }

                    const renderComponent = world.components[lot];

                    if (renderComponent === "*primitive_model*") {
                        continue;
                    }
                    
                    const modelUrl = resourceApi.getGeometryUrl(project, renderComponent);
                    modelPairs.push(Promise.all([modelUrl, object, worldScene]));
                }
            }

            Promise.all(modelPairs).then((pairs) => {
                for (let [modelUrl, object, worldScene] of pairs) {
                    loadModel(modelUrl, object, worldScene);
                }
            });

            if (world.terrain) {
                resourceApi.getGeometryUrl(project, world.terrain).then((terrainUrl) => {
                    fetch(terrainUrl).then((response) => {
                        return response.json();
                    }).then((terrain) => {
                        setTerrain(terrain);
                    });
                });
            }
        }
    }, [world, scene, loadModel, project]);

    useEffect(() => {
        if (models.length > 0) {
            focusCameraOnBounds();
        }
    }, [models, focusCameraOnBounds]);
    
    return (
        <>
            {terrain && scene &&
            <>
                <TerrainLoader terrain={terrain} scene={scene} />
            </>
            }
            <div style={{ width: "100%", height: "100%" }}>
                <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />
            </div>
        </>
    );
};